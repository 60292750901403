import {
  Navbar,
  DefaultHelmet,
  ChestItem,
  ProgressBar,
  DefaultButton,
  BeaconLootIFrame,
} from "@wop/components";
import { ChestListItem } from "@wop/components/ChestListItem";
import {
  getLootObtained,
  textToNewFrontiersDropListNames,
  textToNewFrontiersDropNames,
} from "@wop/helpers/ChestHelper";
import { taskActions } from "@wop/redux/slices/TaskSlice";
import { store } from "@wop/redux/store";
import chestLarge from "@wop/assets/images/chests/chest_large.png";
import chestRays from "@wop/assets/images/chests/chest-rays.png";
import { DefaultButtonType } from "@wop/common";
import { RootState } from "@wop/redux";
import { useDispatch, useSelector } from "react-redux";
import { getUserPayload } from "@wop/utils";
import {
  useGetItemDropsUserEventQuery,
  useGetUserQuery,
  writeOfPassageApi,
} from "@wop/redux/services/api";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { actions, chestActions } from "@wop/redux/slices";
import { Rarity, lootItems, lootItemsByKind } from "@wop/data/lootItems";
import { backgroundImage } from "@wop/assets/images/base64";
import { PreludeNotification } from "@wop/components/PreludeNotification";

const Chests = () => {
  const auth0 = useSelector((state) => (state as RootState).auth0);
  const { user, requestId } = auth0;

  const dispatch = useDispatch();

  const userPayload = getUserPayload(user);
  const { data: userResponse } =
    writeOfPassageApi.endpoints.getUser.useQueryState(userPayload ?? skipToken);

  const { refetch: refetchGetUserQuery, isFetching: isFetchingUser } =
    useGetUserQuery(
      !userResponse?.data.id
        ? skipToken
        : {
            userId: userResponse?.data.id,
          }
    );

  const {
    data: getItemDrops,
    refetch: refetchGetItemDropsUserEventQuery,
    isSuccess: isSuccessGetItemDrops,
  } = useGetItemDropsUserEventQuery(
    !userResponse?.data.id
      ? skipToken
      : {
          userId: userResponse?.data.id,
          eventId: userResponse?.data.currentEventId,
        }
  );

  const isOpenBeaconLoot = useSelector(
    (state) => (state as RootState).task.openBeaconLoot
  );
  const chestMinXpForCurrentChest = useSelector(
    (state) => (state as RootState).chest.chestMinXpForCurrentChest
  );
  const chestMinXpForNextChest = useSelector(
    (state) => (state as RootState).chest.chestMinXpForNextChest
  );
  const chestAvailableForCurrentXp = useSelector(
    (state) => (state as RootState).chest.chestAvailableForCurrentXp
  );

  const openedChests = userResponse?.data.UserEvent?.[0]?.openedChests || 0;
  const userXp = userResponse?.data.UserEvent?.[0]?.xp || 0;
  const actualPrestigePoints = userXp - chestMinXpForCurrentChest;
  const maxPrestigePoints = chestMinXpForNextChest - chestMinXpForCurrentChest;
  const progress = (actualPrestigePoints / maxPrestigePoints) * 100;

  const extraChests = userResponse?.data.UserEvent?.[0]?.extraChests || 0;
  const maxChests = chestAvailableForCurrentXp + extraChests;
  const chestsAvailable =
    maxChests > openedChests ? maxChests - openedChests : 0;

  useEffect(() => {
    if (isOpenBeaconLoot === false) {
      refetchGetUserQuery();
      refetchGetItemDropsUserEventQuery();
    }
  }, [
    isOpenBeaconLoot,
    refetchGetUserQuery,
    refetchGetItemDropsUserEventQuery,
  ]);

  useEffect(() => {
    dispatch(chestActions.updateChestAvailability(userXp));
  }, [dispatch, isFetchingUser, userXp]);

  function renderContent() {
    return (
      <div className="flex flex-col pb-[40px]">
        <div
          className="flex flex-1 flex-col justify-center items-center pt-[100px] pb-[10px]"
          style={{
            backgroundImage: `url(${chestRays})`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: "320px auto",
            backgroundPosition: "center",
          }}
        >
          <img
            className="render-pixelated w-[100px] h-auto select-none"
            src={chestLarge}
            alt="Chest Image"
            style={{
              filter: "drop-shadow(rgba(255,255,255, 0.82) 0px 0px 24px)",
            }}
          />
          <div className="w-full max-w-[300px] lg:max-w-[345px] pt-[36px]">
            <ProgressBar
              progress={progress}
              height={28}
              heightChild={20}
              bgColor={"#27c6e3"}
              border
              transparentBgChild
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-center items-center text-white text-center">
          <p className="font-[CoinageCapsKrugerGray] text-[22px]">
            {actualPrestigePoints}/{maxPrestigePoints} PRESTIGE
          </p>
          <div className="flex-1 w-[120px] pt-[10px]">
            <DefaultButton
              className={chestsAvailable <= 0 ? "opacity-20" : ""}
              title="OPEN"
              type={DefaultButtonType.Primary}
              onClick={() => {
                store.dispatch(taskActions.showBeaconLoot());
                if (!requestId) {
                  dispatch(actions.setRequestMetrics(true));
                }
              }}
              disabled={chestsAvailable === 0}
            />
            {chestsAvailable > 0 && (
              <p className="font-[CoinageCapsKrugerGray] text-orange-300 pt-1 text-[10px]">
                ({chestsAvailable} Available)
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="flex w-full lg:w-1/2 justify-center lg:justify-end pt-[42px]">
            <div>
              <p className="font-[AstoriaRoman] text-[22px] text-white text-center pb-[15px]">
                Loot
              </p>
              <div className="flex flex-wrap max-w-[300px] lg:max-w-[492px] rounded-md">
                {getLootObtained(lootItems, getItemDrops).map((item) => (
                  <div key={item.id} className="p-[3px]">
                    <ChestItem
                      kind={item.kind}
                      quantity={item.quantity}
                      label={textToNewFrontiersDropNames[item.kind] || "Item"}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex w-full lg:w-1/2 justify-center lg:justify-start text-center lg:pl-[50px] py-[45px]">
            <div className="w-[300px] lg:w-[364px]">
              <p className="font-[AstoriaRoman] text-[22px] text-white text-center pb-[15px]">
                History
              </p>
              <div className="max-h-[288px] overflow-auto">
                {isSuccessGetItemDrops && getItemDrops?.data.length === 0 && (
                  <ChestListItem rarity={Rarity.Common} label={"No Items"} />
                )}
                {getItemDrops?.data.map((item, index) => {
                  const rarity = lootItemsByKind[item.kind].rarity;
                  return (
                    <div key={index} className="pb-[10px]">
                      <ChestListItem
                        label={
                          textToNewFrontiersDropListNames[item.kind] || "Item"
                        }
                        date={item.createdAt}
                        rarity={rarity}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <DefaultHelmet title="New Frontiers Quest | Chests" />
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="min-h-[100vh] overflow-visible bg-gradient-to-b from-[rgba(24,23,26,.95)] to-[rgba(10,9,11,.95)]">
          <div className="pt-[100px] min-h-[100vh] backdrop-blur-[5px]">
            {renderContent()}
            <div className="fixed bottom-[100px] z-[90] right-[0px] lg:block">
              <PreludeNotification />
            </div>
          </div>
        </div>
      </div>

      {userResponse?.data.beaconUserId && isOpenBeaconLoot && (
        <BeaconLootIFrame beaconUserId={userResponse.data.beaconUserId} />
      )}
    </>
  );
};

export { Chests };
