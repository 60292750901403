import EarnedPoints from "@wop/assets/images/icons/earned-points.png";
import LevelUp from "@wop/assets/images/icons/level-up.png";
import {
  NotificationType,
  levelUpNotificationsActions,
} from "@wop/redux/slices/LevelUpNotificationsSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

type ProgressNotificationProp = {
  id: string;
  description: string;
  type: NotificationType;
};

const ProgressNotification = ({
  id,
  description,
  type,
}: ProgressNotificationProp) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(levelUpNotificationsActions.removeNotification(id));
    }, 6000);
  }, [dispatch, id]);

  function getImageIcon(type: string) {
    switch (type) {
      case NotificationType.Earned:
        return EarnedPoints;
      case NotificationType.Level_Up:
        return LevelUp;
      default:
        return LevelUp;
    }
  }

  function getBorderBottom(type: string) {
    switch (type) {
      case NotificationType.Earned:
        return (
          <div className="w-[100%] h-[10px] bg-gradient-to-r from-[#2a6180] from-40% to-bg-modal-dark to-60%" />
        );
      case NotificationType.Level_Up:
      default:
        return (
          <div className="w-[100%] h-[10px] bg-gradient-to-r from-[#7f5a2a] from-40% to-bg-modal-dark to-60%" />
        );
    }
  }

  function getDescription(type: string) {
    switch (type) {
      case NotificationType.Earned:
        return (
          <p className="font-[Lato] font-normal text-[16px] text-[#0b9ad5]">
            {description}
          </p>
        );
      case NotificationType.Level_Up:
      default:
        return (
          <p className="font-[Lato] font-normal text-[16px] text-[#f2aa4d]">
            {description}
          </p>
        );
    }
  }

  function getTitle(type: string) {
    switch (type) {
      case NotificationType.Earned:
        return "Earned";
      case NotificationType.Level_Up:
      default:
        return "Level Up";
    }
  }

  return (
    <div className="w-[250px] bg-modal-dark rounded-lg flex flex-col overflow-hidden">
      <div className="flex px-[15px] pt-[5px] pb-[5px]">
        <div className="w-[20px] flex items-center justify-center">
          <img src={getImageIcon(type)} alt="icon notification" />
        </div>
        <div className="flex-1 pl-[15px]">
          <h1 className="font-[Lato] font-bold text-[16px] text-[#d7d6d9]">
            {getTitle(type)}
          </h1>
          {getDescription(type)}
        </div>
        <div className="cursor-pointer">
          <p
            onClick={() => {
              dispatch(levelUpNotificationsActions.removeNotification(id));
            }}
            className="font-[Lato] text-[#8d8d8d]"
          >
            X
          </p>
        </div>
      </div>
      {getBorderBottom(type)}
    </div>
  );
};

export { ProgressNotification };
