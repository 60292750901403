import rankNovice from "@wop/assets/images/icons/rank-novice.png";
import rankScout from "@wop/assets/images/icons/rank-scout.png";
import rankRanger from "@wop/assets/images/icons/rank-ranger.png";
import rankVanguard from "@wop/assets/images/icons/rank-vanguard.png";
import rankLandseeker from "@wop/assets/images/icons/rank-landseeker.png";
import { RankType } from "@wop/common";

type RankImageProps = {
  Novice: string;
  Scout: string;
  Ranger: string;
  Vanguard: string;
  Landseeker: string;
};

const RankImageObject: RankImageProps = {
  Novice: rankNovice,
  Scout: rankScout,
  Ranger: rankRanger,
  Vanguard: rankVanguard,
  Landseeker: rankLandseeker,
};

export const getRankImage = (rank: `${RankType}`) => {
  return rank && RankImageObject[rank]
    ? RankImageObject[rank]
    : RankImageObject.Novice;
};

export const minimumPrestigeForLevel = (l: number) => {
  if (l < 21) {
    return (l - 1) * 500;
  } else {
    return 10500;
  }
};

export const prestigeUntilLevel = (
  currentPrestige: number,
  nextLevel: number
) => {
  return minimumPrestigeForLevel(nextLevel) - currentPrestige;
};
