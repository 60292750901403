import { createSlice } from "@reduxjs/toolkit";

const SFX_ENABLED_STORAGE_KEY = "sfxEnabled";
const MUSIC_ENABLED_STORAGE_KEY = "musicEnabled";

const getInitialSoundState = (key: string, defaultValue = false) => {
  if (!localStorage.getItem(key)) {
    return defaultValue;
  }
  return localStorage.getItem(key) === "true";
};

const initialState = {
  // Whether to play sound effects
  sfxEnabled: getInitialSoundState(SFX_ENABLED_STORAGE_KEY, true),
  // Whether to play background music
  musicEnabled: getInitialSoundState(MUSIC_ENABLED_STORAGE_KEY, false),
  // Whether to temporarily disable the music
  musicOverridden: true,
  // Whether the intro music has been played. This is used so that we
  // loop only after the intro has finished.
  musicIntroPlayed: false,
};

const SoundSlice = createSlice({
  name: "sound",
  initialState,
  reducers: {
    enableSfx: (state, action) => {
      state.sfxEnabled = action.payload;
      localStorage.setItem(
        SFX_ENABLED_STORAGE_KEY,
        state.sfxEnabled.toString()
      );
    },
    enableMusic: (state, action) => {
      state.musicEnabled = action.payload;
      localStorage.setItem(
        MUSIC_ENABLED_STORAGE_KEY,
        state.musicEnabled.toString()
      );
    },
    overrideMusic: (state, action) => {
      state.musicOverridden = action.payload;
    },
    setMusicIntroPlayed: (state) => {
      state.musicIntroPlayed = true;
    },
  },
});

export const soundActions = {
  ...SoundSlice.actions,
};

export default SoundSlice;
