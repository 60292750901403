import { useEffect, useState } from "react";
import { taskActions } from "@wop/redux/slices/TaskSlice";
import { useDispatch } from "react-redux";
import { soundActions } from "@wop/redux/slices";

type BeaconLootIFrameProps = {
  beaconUserId: string;
};
const BeaconLootIFrame = ({ beaconUserId }: BeaconLootIFrameProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(soundActions.overrideMusic(true));

    return () => {
      dispatch(soundActions.overrideMusic(false));
    };
  }, [dispatch]);

  const beaconLootURLStr =
    import.meta.env.VITE_BEACON_LOOT_URL ||
    `${window.location.origin}/gamestub/loot`;
  const beaconLootURL = `${beaconLootURLStr}?expectedUserId=${beaconUserId}`;

  useEffect(() => {
    dispatch(taskActions.setBeaconLootFail());
    function onMessage(event: MessageEvent) {
      try {
        const iframeURL = new URL(beaconLootURL);
        if (
          iframeURL.origin === event.origin &&
          event.data.source === "beacon"
        ) {
          if (event.data.payload?.success === true) {
            dispatch(taskActions.setBeaconLootSuccess());
          }
          if (event.data.payload?.success === false) {
            dispatch(taskActions.setBeaconLootFail());
          }
          if (event.data.payload?.exit === true) {
            dispatch(taskActions.hideBeaconLoot());
          }
        }
      } catch {
        console.log("Missing Loot");
      }
    }
    window.addEventListener("message", onMessage, false);
    return () => {
      window.removeEventListener("message", onMessage, false);
    };
  }, [beaconLootURL, dispatch]);

  return (
    <div className="bg-[rgba(0,0,0,0.8)] fixed top-0 left-0 z-[115] w-[100vw] h-[100vh] flex justify-center items-center select-none">
      {loading && (
        <svg
          className="absolute animate-spin h-[64px] w-[64px] text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}

      <div
        className="beacon-iframe-container"
        style={{
          display: "grid",
          gridTemplate: "1fr / 1fr",
          placeItems: "center",
        }}
      >
        {/* <div
          className="z-[100] w-full h-full col-start-1 col-end-1 row-start-1 row-end-1  flex items-start justify-end pr-[15px] pt-[15px] cursor-pointer pointer-events-none"
          onClick={() => dispatch(taskActions.hideBeaconLoot())}
        >
          <img
            className="w-[30px] h-[30px] pointer-events-auto"
            src={closeIframeButton}
          />
        </div> */}
        <iframe
          src={beaconLootURL}
          title="The Beacon - Loot"
          className="w-full h-full col-start-1 col-end-1 row-start-1 row-end-1 bg-[#0e0a11]"
          onLoad={() => {
            setLoading(false);
          }}
        ></iframe>
      </div>
    </div>
  );
};

export { BeaconLootIFrame };
