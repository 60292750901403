import { styled } from "styled-components";
import {
  tilesetColsCount,
  tilesetRowsCount,
  tilesetYOffset,
  tilesetXOffset,
  animationFrameCount,
  animationDuration,
} from "@wop/helpers/TileAnimationHelper";

// Not using style component's keyframes` here because
// applying a class which has an animation with a keyframe used
// on the same node previously does not reapply

const modalLoadingTs: TileSetInfo = {
  filename: "modal-loading.png",
  size: { width: 3528, height: 128 },
  tileSize: { width: 126, height: 128 },
};

const startFrame = 1;
const endFrame = 3;
const startLoop = 4;
const endLoop = 16 + 1;
const frameDuration = 84;

const ModalLoadingAnimation = styled.div`
  pointer-events: none;
  background-image: ${`url(${modalLoadingTs.filename})`};
  background-size: ${`${100 * tilesetColsCount(modalLoadingTs)}% ${
    100 * tilesetRowsCount(modalLoadingTs)
  }%`};
  background-position-y: ${`${tilesetYOffset(modalLoadingTs, 1)}%`};
  background-repeat: no-repeat;

  &.sequenceModalAnimation {
    animation: ${`
    sequenceModalAnimationKfs ${animationDuration(
      startFrame,
      endFrame,
      frameDuration
    )}s steps(${animationFrameCount(startFrame, endFrame) - 1}, end) forwards
    `};
    animation-delay: "0s";
  }

  &.loopModalAnimation {
    animation: ${`
    loopModalAnimationKfs ${animationDuration(
      startLoop,
      endLoop,
      frameDuration
    )}s steps(${animationFrameCount(startLoop, endLoop) - 1}, end) forwards infinite
    `};
  }

  &.sequenceAndLoopModalAnimation {
    animation: ${`
    sequenceAndLoopModalAnimationSeqKfs ${animationDuration(
      startFrame,
      endFrame,
      frameDuration
    )}s steps(${animationFrameCount(startFrame, endFrame) - 1}, end) forwards,
      
    sequenceAndLoopModalAnimationLoopKfs ${animationDuration(
      startLoop,
      endLoop,
      frameDuration
    )}s ${animationDuration(startFrame, endFrame, frameDuration)}s steps(${
      animationFrameCount(startLoop, endLoop) - 1
    }, end) forwards infinite
    `};
  }

  @keyframes sequenceModalAnimationKfs {
    from {
      background-position-x: ${`${tilesetXOffset(modalLoadingTs, startFrame)}%`};
    }
    to {
      background-position-x: ${`${tilesetXOffset(modalLoadingTs, endFrame)}%`};
    }
  }

  @keyframes loopModalAnimationKfs {
    from {
      background-position-x: ${`${tilesetXOffset(modalLoadingTs, startLoop)}%`};
    }
    to {
      background-position-x: ${`${tilesetXOffset(modalLoadingTs, endLoop)}%`};
    }
  }

  @keyframes sequenceAndLoopModalAnimationSeqKfs {
    from {
      background-position-x: ${`${tilesetXOffset(modalLoadingTs, startFrame)}%`};
    }
    to {
      background-position-x: ${`${tilesetXOffset(modalLoadingTs, endFrame)}%`};
    }
  }

  @keyframes sequenceAndLoopModalAnimationLoopKfs {
    from {
      background-position-x: ${`${tilesetXOffset(modalLoadingTs, startLoop)}%`};
    }
    to {
      background-position-x: ${`${tilesetXOffset(modalLoadingTs, endLoop)}%`};
    }
  }
`;

export { ModalLoadingAnimation };
