export enum Rarity {
  Common = "Common",
  Rare = "Rare",
  Epic = "Epic",
  Legendary = "Legendary",
  Mythical = "Mythical",
}
export type Loot = {
  id: string;
  kind: string;
  quantity: number;
  rarity: Rarity;
};

export const lootItems: Loot[] = [
  {
    id: "1",
    kind: "CURRENCY.skc_medallion_common",
    quantity: 0,
    rarity: Rarity.Common,
  },
  {
    id: "2",
    kind: "CURRENCY.skc_medallion_rare",
    quantity: 0,
    rarity: Rarity.Rare,
  },
  {
    id: "3",
    kind: "CURRENCY.skc_medallion_epic",
    quantity: 0,
    rarity: Rarity.Epic,
  },
  {
    id: "4",
    kind: "CURRENCY.skc_medallion_legendary",
    quantity: 0,
    rarity: Rarity.Legendary,
  },
  {
    id: "5",
    kind: "CURRENCY.skc_medallion_mythical",
    quantity: 0,
    rarity: Rarity.Mythical,
  },
  {
    id: "6",
    kind: "CURRENCY.arb_pouch_common",
    quantity: 0,
    rarity: Rarity.Epic,
  },
  {
    id: "7",
    kind: "CURRENCY.arb_pouch_rare",
    quantity: 0,
    rarity: Rarity.Legendary,
  },
  {
    id: "8",
    kind: "CURRENCY.arb_pouch_epic",
    quantity: 0,
    rarity: Rarity.Legendary,
  },
  {
    id: "9",
    kind: "CURRENCY.arb_pouch_legendary",
    quantity: 0,
    rarity: Rarity.Mythical,
  },
  {
    id: "10",
    kind: "CURRENCY.points_bundle_small",
    quantity: 0,
    rarity: Rarity.Common,
  },
  {
    id: "11",
    kind: "CURRENCY.points_bundle_medium",
    quantity: 0,
    rarity: Rarity.Rare,
  },
  {
    id: "12",
    kind: "CURRENCY.points_bundle_large",
    quantity: 0,
    rarity: Rarity.Epic,
  },
  {
    id: "13",
    kind: "CURRENCY.points_bundle_huge",
    quantity: 0,
    rarity: Rarity.Legendary,
  },
  {
    id: "14",
    kind: "CURRENCY.lux_wings",
    quantity: 0,
    rarity: Rarity.Mythical,
  },
  {
    id: "15",
    kind: "CURRENCY.umbra_wings",
    quantity: 0,
    rarity: Rarity.Mythical,
  },
];

const lootItemsByKind = lootItems.reduce(
  (acc, item) => {
    acc[item.kind] = item;
    return acc;
  },
  {} as Record<string, Loot>
);

export { lootItemsByKind };
