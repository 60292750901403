import lux_wings from "@wop/assets/images/chests/CURRENCY.lux_wings.png";
import umbra_wings from "@wop/assets/images/chests/CURRENCY.umbra_wings.png";
import arb_pouch_common from "@wop/assets/images/chests/CURRENCY.arb_pouch_common.png";
import arb_pouch_epic from "@wop/assets/images/chests/CURRENCY.arb_pouch_epic.png";
import arb_pouch_legendary from "@wop/assets/images/chests/CURRENCY.arb_pouch_legendary.png";
import arb_pouch_rare from "@wop/assets/images/chests/CURRENCY.arb_pouch_rare.png";
import points_bundle_huge from "@wop/assets/images/chests/CURRENCY.points_bundle_huge.png";
import points_bundle_large from "@wop/assets/images/chests/CURRENCY.points_bundle_large.png";
import points_bundle_medium from "@wop/assets/images/chests/CURRENCY.points_bundle_medium.png";
import points_bundle_small from "@wop/assets/images/chests/CURRENCY.points_bundle_small.png";
import skc_medallion_common from "@wop/assets/images/chests/CURRENCY.skc_medallion_common.png";
import skc_medallion_epic from "@wop/assets/images/chests/CURRENCY.skc_medallion_epic.png";
import skc_medallion_legendary from "@wop/assets/images/chests/CURRENCY.skc_medallion_legendary.png";
import skc_medallion_mythical from "@wop/assets/images/chests/CURRENCY.skc_medallion_mythical.png";
import skc_medallion_rare from "@wop/assets/images/chests/CURRENCY.skc_medallion_rare.png";
import { Loot } from "@wop/data/lootItems";

type ItemImageInfo = {
  [key: string]: string;
};
type ItemFriendlyName = {
  [key: string]: string;
};

export const textToNewFrontiersDropNames: ItemFriendlyName = {
  "CURRENCY.skc_medallion_common": "Common Medallion",
  "CURRENCY.skc_medallion_rare": "Rare Medallion",
  "CURRENCY.skc_medallion_epic": "Epic Medallion",
  "CURRENCY.skc_medallion_legendary": "Legendary Medallion",
  "CURRENCY.skc_medallion_mythical": "Mythical Medallion",
  "CURRENCY.arb_pouch_common": "Medium ARB Pouch (10 tokens)",
  "CURRENCY.arb_pouch_rare": "Heavy ARB Pouch (50 tokens)",
  "CURRENCY.arb_pouch_epic": "Hefty ARB Pouch (100 tokens)",
  "CURRENCY.arb_pouch_legendary": "Massive ARB Pouch (1000 tokens)",
  "CURRENCY.lux_wings": "Shimmering Skyblades",
  "CURRENCY.umbra_wings": "Abyssal Shroud",
  "CURRENCY.points_bundle_small": "Small Points Bundle",
  "CURRENCY.points_bundle_medium": "Medium Points Bundle",
  "CURRENCY.points_bundle_large": "Large Points Bundle",
  "CURRENCY.points_bundle_huge": "Huge Points Bundle",
};

export const textToNewFrontiersDropListNames: ItemFriendlyName = {
  "CURRENCY.skc_medallion_common": "Common Medallion",
  "CURRENCY.skc_medallion_rare": "Rare Medallion",
  "CURRENCY.skc_medallion_epic": "Epic Medallion",
  "CURRENCY.skc_medallion_legendary": "Legendary Medallion",
  "CURRENCY.skc_medallion_mythical": "Mythical Medallion",
  "CURRENCY.arb_pouch_common": "x10 ARB Tokens",
  "CURRENCY.arb_pouch_rare": "x50 ARB Tokens",
  "CURRENCY.arb_pouch_epic": "x100 ARB Tokens",
  "CURRENCY.arb_pouch_legendary": "x1000 ARB Tokens",
  "CURRENCY.lux_wings": "Shimmering Skyblades",
  "CURRENCY.umbra_wings": "Abyssal Shroud",
  "CURRENCY.points_bundle_small": "+100 Prestige Points",
  "CURRENCY.points_bundle_medium": "+200 Prestige Points",
  "CURRENCY.points_bundle_large": "+500 Prestige Points",
  "CURRENCY.points_bundle_huge": "+1000 Prestige Points",
};

export const textToNewFrontiersDropImage: ItemImageInfo = {
  "CURRENCY.skc_medallion_common": skc_medallion_common,
  "CURRENCY.skc_medallion_rare": skc_medallion_rare,
  "CURRENCY.skc_medallion_epic": skc_medallion_epic,
  "CURRENCY.skc_medallion_legendary": skc_medallion_legendary,
  "CURRENCY.skc_medallion_mythical": skc_medallion_mythical,
  "CURRENCY.arb_pouch_common": arb_pouch_common,
  "CURRENCY.arb_pouch_rare": arb_pouch_rare,
  "CURRENCY.arb_pouch_epic": arb_pouch_epic,
  "CURRENCY.arb_pouch_legendary": arb_pouch_legendary,
  "CURRENCY.lux_wings": lux_wings,
  "CURRENCY.umbra_wings": umbra_wings,
  "CURRENCY.points_bundle_small": points_bundle_small,
  "CURRENCY.points_bundle_medium": points_bundle_medium,
  "CURRENCY.points_bundle_large": points_bundle_large,
  "CURRENCY.points_bundle_huge": points_bundle_huge,
};

export const getFormatDateChestItem = (date: Date) => {
  const d = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const formattedDate: string = d.toLocaleDateString("en-US", options);
  return formattedDate;
};

export const getLootObtained = (loot: Loot[], itemDrops?: itemDrops) => {
  const lootObtained = loot.map((lootItem) => {
    const lootItemTmp = { ...lootItem };
    itemDrops?.data.forEach((itemDrop) => {
      if (lootItemTmp.kind === itemDrop.kind) lootItemTmp.quantity++;
    });
    return lootItemTmp;
  });
  return lootObtained;
};
