import { Helmet } from "react-helmet-async";

type DefaultHelmetProps = {
  title: string;
};
const DefaultHelmet = ({ title }: DefaultHelmetProps) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export { DefaultHelmet };
