import styled from "styled-components";

const LoadingAnimation = styled.div`
  @keyframes loading-animation {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(90deg);
    }
    40% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

export { LoadingAnimation };
