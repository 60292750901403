import { createSlice } from "@reduxjs/toolkit";
import {
  maxChestFromPoints,
  minPointsForChest,
} from "@wop/helpers/ChestAvailabilityHelper";
import { isNumber } from "lodash";

const initialState = {
  chestAvailableForCurrentXp: 0,
  chestMinXpForCurrentChest: 0,
  chestMinXpForNextChest: 0,
};

const ChestSlice = createSlice({
  name: "chest",
  initialState: initialState,
  reducers: {
    updateChestAvailability: (state, action) => {
      if (isNumber(action.payload)) {
        state.chestAvailableForCurrentXp = maxChestFromPoints(action.payload);
        state.chestMinXpForCurrentChest = minPointsForChest(
          state.chestAvailableForCurrentXp
        );
        state.chestMinXpForNextChest = minPointsForChest(
          state.chestAvailableForCurrentXp + 1
        );
      }
    },
  },
});

export const chestActions = {
  ...ChestSlice.actions,
};

export default ChestSlice;
