import { ProgressBar } from "@wop/components/ProgressBar";
import { RankType } from "@wop/common";
import { getRankImage } from "@wop/helpers/RankHelper";

type PrestigePointsTooltipProps = {
  rank: RankType;
  level: number;
  levelCompletionPercentage: number;
  prestigeUntilNextLevel: number;
};

const PrestigePointsTooltip = ({
  rank,
  level = 0,
  levelCompletionPercentage = 0,
  prestigeUntilNextLevel = 0,
}: PrestigePointsTooltipProps) => {
  return (
    <>
      <div className="bg-black rounded-[6px]">
        <div className="bg-gradient-to-t from-[#000000] via-[#1e1a1f] to-[#1e1a1f] px-[15px] pt-[15px] rounded-t-[6px]">
          <div className="flex">
            <div className="flex-auto">
              <p className="font-[AstoriaRoman] leading-normal text-[22px] bg-gradient-to-b from-[#fde2ff] to-[#b8a8ff] inline-block text-transparent bg-clip-text">
                {rank}
              </p>
              <p className="font-[Lato] leading-normal text-[16px] bg-gradient-to-b from-[#fde2ff] to-[#b8a8ff] text-transparent bg-clip-text">
                Level {level}
              </p>
            </div>
            <div className="flex-none self-center">
              <img
                className="render-pixelated"
                src={getRankImage(rank)}
                alt="Rank Image"
              />
            </div>
          </div>
          {level < 21 && (
            <>
              <div className="flex pt-[10px]">
                <ProgressBar
                  progress={levelCompletionPercentage * 100}
                  height={8}
                />
              </div>
              <div className="flex">
                <p className="w-full font-[Lato] text-[12px] text-right text-[rgb(240,157,47)] pt-[5px]">
                  {prestigeUntilNextLevel} prestige until level {level + 1}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="p-[15px]">
          <div className="flex font-[Lato] text-[16px]">
            <div className="flex-none">
              <img
                className="render-pixelated"
                src={getRankImage(RankType.Novice)}
                alt="Rank Image"
              />
            </div>
            <div className="w-[82px] self-center ml-[15px]">
              <p className="leading-none align-middle">{RankType.Novice}</p>
            </div>
            <div className="flex-1 self-center pl-[10px]">
              <p className="text-right leading-none whitespace-nowrap">
                Level 1 - 5
              </p>
            </div>
          </div>
          <div className="flex font-[Lato] text-[16px] pt-[15px]">
            <div className="flex-none">
              <img
                className="render-pixelated"
                src={getRankImage(RankType.Scout)}
                alt="Rank Image"
              />
            </div>
            <div className="w-[82px] self-center ml-[15px]">
              <p className="leading-none align-middle">{RankType.Scout}</p>
            </div>
            <div className="flex-1 self-center pl-[10px]">
              <p className="text-right leading-none whitespace-nowrap">
                Level 6 - 10
              </p>
            </div>
          </div>
          <div className="flex font-[Lato] text-[16px] pt-[15px]">
            <div className="flex-none">
              <img
                className="render-pixelated"
                src={getRankImage(RankType.Ranger)}
                alt="Rank Image"
              />
            </div>
            <div className="w-[82px] self-center ml-[15px]">
              <p className="leading-none align-middle">{RankType.Ranger}</p>
            </div>
            <div className="flex-1 self-center pl-[10px]">
              <p className="text-right leading-none whitespace-nowrap">
                Level 11-15
              </p>
            </div>
          </div>
          <div className="flex font-[Lato] text-[16px] pt-[15px]">
            <div className="flex-none">
              <img
                className="render-pixelated"
                src={getRankImage(RankType.Vanguard)}
                alt="Rank Image"
              />
            </div>
            <div className="w-[82px] self-center ml-[15px]">
              <p className="leading-none align-middle">{RankType.Vanguard}</p>
            </div>
            <div className="flex-1 self-center pl-[10px]">
              <p className="text-right leading-none whitespace-nowrap">
                Level 16-20
              </p>
            </div>
          </div>
          <div className="flex font-[Lato] text-[16px] pt-[15px]">
            <div className="flex-none">
              <img
                className="render-pixelated"
                src={getRankImage(RankType.Landseeker)}
                alt="Rank Image"
              />
            </div>
            <div className="w-[82px] self-center ml-[15px]">
              <p className="leading-none align-middle">{RankType.Landseeker}</p>
            </div>
            <div className="flex-1 self-center pl-[10px]">
              <p className="text-right leading-none whitespace-nowrap">
                Level 21
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { PrestigePointsTooltip };
