import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "@wop/router";
import {
  Auth0Callback,
  // SoundControls,
  NotificationsList,
  // BeaconClientModal,
} from "@wop/components";
// import { useMusic } from "@wop/hooks/Music";

function App() {
  // useMusic();
  return (
    <BrowserRouter>
      <AppRouter />
      <Auth0Callback />
      {/* <SoundControls /> */}
      <NotificationsList />
      {/* <BeaconClientModal /> */}
    </BrowserRouter>
  );
}

export default App;
