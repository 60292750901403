import {
  autoUpdate,
  flip,
  useFloating,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { useEffect, useRef, useState } from "react";
import { PrestigePointsTooltip } from "@wop/components";
import {
  getRankImage,
  minimumPrestigeForLevel,
  prestigeUntilLevel,
} from "@wop/helpers/RankHelper";
import { motion } from "framer-motion";
import { RankType } from "@wop/common";
import { levelToRank, prestigeToLevel } from "@wop/utils";
import {
  NotificationType,
  levelUpNotificationsActions,
} from "@wop/redux/slices/LevelUpNotificationsSlice";
import { useDispatch } from "react-redux";

type showXpProps = {
  xp: number;
};

const ShowXP = ({ xp }: showXpProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const previousRank = useRef(RankType.Novice);
  const previousLevel = useRef(prestigeToLevel(xp));
  const previousXp = useRef(xp);

  const dispatch = useDispatch();

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    middleware: [flip()],
    open: false,
    onOpenChange: (open, event) => {
      if (event?.type == "mouseenter") {
        setIsTooltipOpen(true);
      } else if (event?.type == "mouseleave") {
        setIsTooltipOpen(false);
      }
    },
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context);
  const { getFloatingProps } = useInteractions([hover]);
  const progressBarPrestige =
    minimumPrestigeForLevel(prestigeToLevel(xp) + 1) -
    minimumPrestigeForLevel(prestigeToLevel(xp));
  const prestigeUntilNextLevel = prestigeUntilLevel(
    xp,
    prestigeToLevel(xp) + 1
  );

  useEffect(() => {
    const level = prestigeToLevel(xp);
    if (previousXp.current !== xp && previousLevel.current !== level) {
      dispatch(
        levelUpNotificationsActions.addNotification({
          level: level,
          type: NotificationType.Level_Up,
        })
      );
    }
    previousRank.current = levelToRank(level);
    previousLevel.current = level;
    previousXp.current = xp;
  }, [xp, dispatch]);

  const shouldAnimateLevelup =
    previousRank.current != levelToRank(prestigeToLevel(xp));
  return (
    <div
      ref={refs.setReference}
      className="cursor-default flex items-center justify-center min-w-[180px]"
    >
      <div className="flex-1">
        <p className="w-full font-[AstoriaRoman] text-right leading-normal text-[18px] xl:text-[22px] bg-gradient-to-b from-[#fde2ff] to-[#b8a8ff] inline-block text-transparent bg-clip-text">
          {levelToRank(prestigeToLevel(xp))}
        </p>
      </div>
      <div className="self-center px-[5px] render-pixelated">
        <motion.div
          animate={
            shouldAnimateLevelup
              ? {
                  scale: [1, 1.25, 0.75, 1],
                }
              : {}
          }
        >
          <img
            className="w-[42px] h-[32px] select-none"
            src={getRankImage(levelToRank(prestigeToLevel(xp)))}
            alt="Rank Image"
          />
        </motion.div>
      </div>
      {isTooltipOpen && xp >= 0 && (
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          style={{ ...floatingStyles, pointerEvents: "none" }}
          className="z-[70]"
        >
          <PrestigePointsTooltip
            rank={levelToRank(prestigeToLevel(xp))}
            level={prestigeToLevel(xp)}
            levelCompletionPercentage={
              (progressBarPrestige - prestigeUntilNextLevel) /
              progressBarPrestige
            }
            prestigeUntilNextLevel={prestigeUntilLevel(
              xp,
              prestigeToLevel(xp) + 1
            )}
          />
        </div>
      )}
    </div>
  );
};

export { ShowXP };
