// How fast the curve grows
const growth_factor =
  Number(import.meta.env.VITE_CHEST_GROWTH_FACTOR) || 1.05139868;
// First chest we calculate with the formula
const bottom_chest = 16;
// Starting required points for bottom chest (without adding previous)
const bottom_points = 1500;
// Base points required (based on the points table's last value)
const base_points = 12350;
// Chest to start printing from
const initial_chest = 16;
// Amount of chests to show
const chests_to_print = 100;
// Points multiple
const points_multiple = 50;

// Amount of points to get chests for
const points_for_chests = 390000;

// Minimum prestige per chest 1 - 15
const base_values: MinimumPrestigePerChest = {
  1: 500,
  2: 1000,
  3: 1600,
  4: 2200,
  5: 2900,
  6: 3600,
  7: 4400,
  8: 5300,
  9: 6300,
  10: 7500,
  11: 8500,
  12: 9450,
  13: 10450,
  14: 11400,
  15: 12350,
};

export function minPointsForChest(
  chestNumber: number,
  memo: MinimumPrestigePerChest = {}
) {
  if (chestNumber === 0) return 0;
  if (chestNumber < bottom_chest) {
    return base_values[chestNumber];
  }
  return min_points_for_chest(
    chestNumber,
    growth_factor,
    bottom_points,
    bottom_chest,
    base_points,
    memo
  );
}

export function maxChestFromPoints(
  xp: number,
  memo: MinimumPrestigePerChest = {}
) {
  if (xp < base_points + bottom_points) {
    let chestCount = 1;
    while (base_values[chestCount] && base_values[chestCount] <= xp) {
      chestCount += 1;
    }
    return chestCount - 1;
  }
  return max_chest_number_from_points(
    xp,
    growth_factor,
    bottom_points,
    bottom_chest,
    base_points,
    memo
  );
}

// added min points for chests - cumulative sum similar to max_chest_number_from_points
export function min_points_for_chest(
  target_chest: number,
  growth_factor: number,
  bottom_points: number,
  bottom_chest: number,
  base_points: number,
  memo: MinimumPrestigePerChest = {}
) {
  let cumulative_points = base_points;
  let chest_number = bottom_chest - 1;

  while (chest_number < target_chest) {
    chest_number += 1;
    const chest_points = required_points(
      chest_number,
      growth_factor,
      bottom_points,
      bottom_chest,
      memo
    );
    cumulative_points += chest_points;
  }
  // Return minimum points required for target_chest
  return cumulative_points;
}

// Original code from python
function floor_to_multiple(number: number, multiple: number) {
  return Math.floor(number / multiple) * multiple;
}

function ceil_to_multiple(number: number, multiple: number) {
  return Math.ceil(number / multiple) * multiple;
}

function required_points(
  chest_number: number,
  growth_factor: number,
  bottom_points: number,
  bottom_chest: number,
  memo: MinimumPrestigePerChest = {}
) {
  return ceil_to_multiple(
    _required_points(
      chest_number,
      growth_factor,
      bottom_points,
      bottom_chest,
      memo
    ),
    points_multiple
  );
}

function _required_points(
  chest_number: number,
  growth_factor: number,
  bottom_points: number,
  bottom_chest: number,
  memo: MinimumPrestigePerChest = {}
) {
  let result = memo[chest_number];
  if (result) return result;

  if (chest_number <= 0) return 0;

  if (chest_number == bottom_chest) return bottom_points;

  result =
    _required_points(
      chest_number - 1,
      growth_factor,
      bottom_points,
      bottom_chest,
      memo
    ) * growth_factor;

  memo[chest_number] = result;

  return result;
}

function max_chest_number_from_points(
  points: number,
  growth_factor: number,
  bottom_points: number,
  bottom_chest: number,
  base_points: number,
  memo: MinimumPrestigePerChest = {}
) {
  let cumulative_points = base_points;
  let chest_number = bottom_chest - 1;

  while (cumulative_points <= points) {
    chest_number += 1;
    const chest_points = required_points(
      chest_number,
      growth_factor,
      bottom_points,
      bottom_chest,
      memo
    );
    cumulative_points += chest_points;
  }
  // Return the last valid chest number before exceeding points
  return chest_number - 1;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function print_chests_by_points() {
  // points_for_chests
  const chests = max_chest_number_from_points(
    floor_to_multiple(points_for_chests, points_multiple),
    growth_factor,
    bottom_points,
    bottom_chest,
    base_points
  );

  console.log(`Chests: ${chests} for ${points_for_chests} points`);
}

function range(startAt: number, size: number): ReadonlyArray<number> {
  return [...Array(size).keys()].map((i) => i + startAt);
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function print_points_per_chest() {
  const chest_numbers: number[] = [];
  const points_per_chest: number[] = [];
  const cumulative_points: number[] = [];

  console.log("======= POINTS PER CHEST ========");

  range(initial_chest, chests_to_print - initial_chest + 1).forEach(
    (chest_number) => {
      const points = required_points(
        chest_number,
        growth_factor,
        bottom_points,
        bottom_chest
      );
      console.log(`* Chest ${chest_number}: ${points}`);
      chest_numbers.push(chest_number);
      points_per_chest.push(points);
    }
  );

  console.log("======= TOTAL POINTS ========");
  // Print table of cumulative points for each level
  let cumulative = ceil_to_multiple(base_points, points_multiple);

  range(initial_chest, chests_to_print - initial_chest + 1).forEach(
    (chest_number) => {
      const points = required_points(
        chest_number,
        growth_factor,
        bottom_points,
        bottom_chest
      );
      cumulative += points;
      console.log(`* Total points for chest ${chest_number}: ${cumulative}`);
      cumulative_points.push(cumulative);
    }
  );
}

// console.time("print_points_per_chest");
// print_points_per_chest();
// console.timeEnd("print_points_per_chest");

// console.time("print_chests_by_points");
// print_chests_by_points();
// console.timeEnd("print_chests_by_points");
