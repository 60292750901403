import { ReactElement } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { linkContext } from "@wop/utils";
import { AccountType } from "@wop/common";

const oauthDomain = import.meta.env.VITE_AUTH0_DOMAIN;
const oauthClientId = import.meta.env.VITE_AUTH0_CLIENT_ID;

const mainProviderAutorizationParams = {
  audience: `https://${oauthDomain}/api/v2/`,
  redirect_uri: `${window.location.origin}/login`,
  scope:
    "openid email profile read:current_user update:current_user_identities",
  connection: AccountType.Twitter,
};

const secondaryProviderAutorizationParams = {
  audience: `https://${oauthDomain}/api/v2/`,
  redirect_uri: window.location.origin,
  scope: "openid email profile read:current_user",
};

type Props = {
  children: ReactElement;
};

const Auth0MultiProvider = ({ children }: Props) => {
  return (
    <Auth0Provider
      domain={oauthDomain}
      clientId={oauthClientId}
      authorizationParams={mainProviderAutorizationParams}
      cacheLocation="localstorage"
    >
      <Auth0Provider
        context={linkContext}
        domain={oauthDomain}
        clientId={oauthClientId}
        authorizationParams={secondaryProviderAutorizationParams}
        skipRedirectCallback={true}
      >
        {children}
      </Auth0Provider>
    </Auth0Provider>
  );
};

export { Auth0MultiProvider };
