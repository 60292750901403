import { LoadingWithProgressBar } from "@wop/components";

type DefaultLoadingProps = {
  progressPercent?: number;
};

const Loading = ({ progressPercent }: DefaultLoadingProps) => {
  return (
    <div className="fixed w-[100vw] min-h-[100vh] flex items-center justify-center bg-gradient-to-b from-[#18171a] to-[#0a090b]">
      <LoadingWithProgressBar percent={progressPercent} />
    </div>
  );
};

export { Loading };
