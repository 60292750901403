import { styled, css, keyframes } from "styled-components";
import {
  tilesetColsCount,
  tilesetRowsCount,
  tilesetXOffset,
  tilesetYOffset,
} from "@wop/helpers/TileAnimationHelper";

type NodeMouseAnimationProps = {
  readonly $tileSetInfo: TileSetInfo;
  readonly $frameDuration: number;
  readonly $sourceRow: number;
};

const animationKeyframes = (
  tileSetInfo: TileSetInfo,
  startFrame: number,
  endFrame: number
) => keyframes`
from {
  background-position-x: 
    ${tilesetXOffset(tileSetInfo, startFrame)}%;
}
to {
  background-position-x: 
    ${tilesetXOffset(tileSetInfo, endFrame)}%;
}
`;

enum mouseAnimation {
  hoverEnterStart = 0,
  hoverEnterEnd = 1,
  hoverIdle = 2,
  hoverExitStart = 3,
  hoverExitEnd = 9,
  selectedEnterStart = 10,
  selectedEnterEnd = 18,
  selectedIdle = 19,
  selectedExitStart = 20,
  selectedExitEnd = 21,
}
function animationFrameCount(startFrame: number, endFrame: number) {
  return endFrame - startFrame + 1;
}
function animationDuration(
  startFrame: number,
  endFrame: number,
  frameDuration: number
) {
  return (animationFrameCount(startFrame, endFrame) * frameDuration) / 1000;
}
const NodeMouseEventOverlay = styled.div<NodeMouseAnimationProps>`
  pointer-events: none;
  background-repeat: no-repeat;

  &.hoverAnimation {
    background-size: ${({ $tileSetInfo }) =>
      `${100 * tilesetColsCount($tileSetInfo)}% ${100 * tilesetRowsCount($tileSetInfo)}%`};
    background-image: ${({ $tileSetInfo }) => `url(${$tileSetInfo.filename})`};
    background-position-y: ${({ $tileSetInfo, $sourceRow }) =>
      `${tilesetYOffset($tileSetInfo, $sourceRow)}%`};
    animation: ${({ $tileSetInfo, $frameDuration }) => css`
      ${animationKeyframes(
        $tileSetInfo,
        mouseAnimation.hoverEnterStart,
        mouseAnimation.hoverIdle
      )} ${animationDuration(
        mouseAnimation.hoverEnterStart,
        mouseAnimation.hoverIdle,
        $frameDuration
      )}s steps(${animationFrameCount(
        mouseAnimation.hoverEnterStart,
        mouseAnimation.hoverIdle
      ) - 1}, end) forwards
    `};
  }
  &.hoverExitAnimation {
    background-size: ${({ $tileSetInfo }) =>
      `${100 * tilesetColsCount($tileSetInfo)}% ${100 * tilesetRowsCount($tileSetInfo)}%`};
    background-image: ${({ $tileSetInfo }) => `url(${$tileSetInfo.filename})`};
    background-position-y: ${({ $tileSetInfo, $sourceRow }) =>
      `${tilesetYOffset($tileSetInfo, $sourceRow)}%`};
    animation: ${({ $tileSetInfo, $frameDuration }) => css`
      ${animationKeyframes(
        $tileSetInfo,
        mouseAnimation.hoverExitStart,
        mouseAnimation.hoverExitEnd + 1
      )} ${animationDuration(
        mouseAnimation.hoverExitStart,
        mouseAnimation.hoverExitEnd,
        $frameDuration
      )}s steps(${animationFrameCount(
        mouseAnimation.hoverExitStart,
        mouseAnimation.hoverExitEnd + 1
      ) - 1}, end) forwards
    `};
  }

  &.selectedAnimation {
    background-size: ${({ $tileSetInfo }) =>
      `${100 * tilesetColsCount($tileSetInfo)}% ${100 * tilesetRowsCount($tileSetInfo)}%`};
    background-image: ${({ $tileSetInfo }) => `url(${$tileSetInfo.filename})`};
    background-position-y: ${({ $tileSetInfo, $sourceRow }) =>
      `${tilesetYOffset($tileSetInfo, $sourceRow)}%`};
    animation: ${({ $tileSetInfo, $frameDuration }) => css`
      ${animationKeyframes(
        $tileSetInfo,
        mouseAnimation.selectedEnterStart,
        mouseAnimation.selectedIdle
      )} ${animationDuration(
        mouseAnimation.selectedEnterStart,
        mouseAnimation.selectedIdle,
        $frameDuration
      )}s steps(${animationFrameCount(
        mouseAnimation.selectedEnterStart,
        mouseAnimation.selectedIdle
      ) - 1}, end) forwards
    `};
  }
  &.selectedExitAnimation {
    background-size: ${({ $tileSetInfo }) =>
      `${100 * tilesetColsCount($tileSetInfo)}% ${100 * tilesetRowsCount($tileSetInfo)}%`};
    background-image: ${({ $tileSetInfo }) => `url(${$tileSetInfo.filename})`};
    background-position-y: ${({ $tileSetInfo, $sourceRow }) =>
      `${tilesetYOffset($tileSetInfo, $sourceRow)}%`};
    animation: ${({ $tileSetInfo, $frameDuration }) => css`
      ${animationKeyframes(
        $tileSetInfo,
        mouseAnimation.selectedExitStart,
        mouseAnimation.selectedExitEnd + 1
      )} ${animationDuration(
        mouseAnimation.selectedExitStart,
        mouseAnimation.selectedExitEnd,
        $frameDuration
      )}s steps(${animationFrameCount(
        mouseAnimation.selectedExitStart,
        mouseAnimation.selectedExitEnd + 1
      ) - 1}, end) forwards
    `};
  }
`;

export { NodeMouseEventOverlay };
