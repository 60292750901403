import styled from "styled-components";

type DefaultTooltipProps = {
  show: boolean;
  message: string;
};

const ToolTipAnimation = styled.div`
  @keyframes fadeInTooltip {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOutTooltip {
    from {
      opacity: 1;
      display: block;
    }
    to {
      opacity: 0;
      display: none;
    }
  }
`;

const DefaultTooltip = ({ show, message }: DefaultTooltipProps) => {
  return (
    <ToolTipAnimation
      style={{
        animation: `${show ? "fadeInTooltip" : "fadeOutTooltip"} ease 2s`,
      }}
      className={`absolute whitespace-nowrap top-[-70%] left-[40%] ${!show && "hidden"}`}
    >
      <span className="relative z-10 p-3 text-xs text-white bg-gray-600 shadow-lg rounded-md">
        {message}
      </span>
      <div className="w-3 h-3 ml-2 rotate-45 bg-gray-600" />
    </ToolTipAnimation>
  );
};

export { DefaultTooltip };
