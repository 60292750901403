import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux";
import {
  FingerprintJSPro,
  useVisitorData,
} from "@fingerprintjs/fingerprintjs-pro-react";
import { useSaveUserMetricsMutation } from "@wop/redux/services/api";
import { actions } from "@wop/redux/slices";
import * as Sentry from "@sentry/react";

export function useFetchMetrics() {
  const { requestId, requestMetrics, requestingMetrics, currentUserId } =
    useSelector((state) => (state as RootState).auth0);

  const {
    isLoading: isLoadingMetrics,
    error: metricsError,
    data: metricsData,
    // NOTE: this function changes with every rerender, so we can't use it as a dependency
    // in useEffect callbacks
    getData,
  } = useVisitorData(
    {
      timeout: 15000,
    },
    {
      immediate: false,
    }
  );

  const [saveUserMetricsMutation] = useSaveUserMetricsMutation();

  const dispatch = useDispatch();

  const retryIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (requestMetrics && !requestingMetrics && !requestId) {
      getData({
        linkedId: currentUserId,
      });
      dispatch(actions.setRequestingMetrics(true));
    }
  }, [requestId, requestingMetrics, requestMetrics, currentUserId]);

  useEffect(() => {
    if (isLoadingMetrics || !currentUserId || requestId || !requestingMetrics) {
      return;
    }

    (async function () {
      let retry = false;

      if (metricsData) {
        try {
          const response = await saveUserMetrics(
            saveUserMetricsMutation,
            currentUserId,
            metricsData.requestId
          );

          if (response.success) {
            dispatch(actions.setRequestId(metricsData.requestId));
            dispatch(actions.setRequestMetrics(false));
            dispatch(actions.setRequestingMetrics(false));

            if (retryIntervalRef.current) {
              clearInterval(retryIntervalRef.current);
            }
          } else {
            retry = true;
          }
        } catch (err) {
          retry = true;
        }
      } else if (metricsError) {
        retry = true;

        const { name, message } = metricsError;
        if (message !== FingerprintJSPro.ERROR_RATE_LIMIT) {
          Sentry.addBreadcrumb({
            message: `GetMetrics error: ${metricsError.message}`,
            level: "error",
          });
          Sentry.captureException(
            new Error(`GetMetrics data error ${name}: ${message}`)
          );
        }
      } else {
        retry = true;

        Sentry.addBreadcrumb({
          message: `GetMetrics unhandled error`,
          level: "error",
        });
        Sentry.captureException(new Error("Failed retrieving metrics"));
      }

      if (retry && !retryIntervalRef.current) {
        retryIntervalRef.current = setInterval(() => {
          getData({
            linkedId: currentUserId,
          });
        }, 2000);
      }
    })().catch((err) => {
      Sentry.addBreadcrumb({
        message: `HandleMetrics error`,
        level: "error",
      });
      Sentry.captureException(err);
    });
  }, [
    isLoadingMetrics,
    metricsData,
    metricsError,
    saveUserMetricsMutation,
    currentUserId,
    dispatch,
    requestId,
    requestingMetrics,
  ]);
}

async function saveUserMetrics(
  saveUserMetricsMutation: ReturnType<typeof useSaveUserMetricsMutation>[0],
  userId: string,
  requestId: string
) {
  return saveUserMetricsMutation({
    userId: userId,
    data: {
      requestId,
    },
  })
    .unwrap()
    .then(() => {
      return { success: true as const };
    })
    .catch((error) => {
      Sentry.addBreadcrumb({
        message: `SaveMetrics error`,
        data: error,
        level: "error",
      });
      Sentry.captureException(new Error("Failed saving metrics"));

      return {
        success: false as const,
        errorCode: error.status,
      };
    });
}
