import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { RedirectLoginOptions } from "@auth0/auth0-react";
import debounce from "lodash/debounce";

const CheckYourLootButton = () => {
  const { loginWithRedirect } = useAuth0();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const referral = params.get("referral");

  const loginObject: RedirectLoginOptions = {
    appState: {
      returnTo: `${window.location.origin}/login`,
    },
  };

  if (referral) {
    loginObject.authorizationParams = {
      referralCode: referral,
    };
  }

  const handleLogin = async () => {
    await loginWithRedirect(loginObject);
  };

  return (
    <button
      className="font-[CoinageCapsKrugerGray] text-[22px] text-[#bf4807] leading-none py-[16px] px-[18px] border-[1px] border-[#bf4807] rounded-md"
      onClick={debounce(handleLogin, 200, { leading: true, trailing: true })}
    >
      CHECK YOUR LOOT
    </button>
  );
};

export { CheckYourLootButton };
