type ProgressBarProps = {
  progress?: number;
  height?: number;
  bgColor?: string;
  heightChild?: number;
  transparentBgChild?: boolean;
  border?: boolean;
};

const ProgressBar = ({
  progress = 0,
  height = 8,
  bgColor = "#fd9428",
  heightChild = 8,
  transparentBgChild = false,
  border = false,
}: ProgressBarProps) => {
  const parentDiv = {
    height: height,
    borderColor: `${bgColor}`,
  };
  const childDiv = {
    width: `${progress}%`,
    backgroundColor: `${bgColor}`,
    height: heightChild,
  };

  return (
    <div
      style={parentDiv}
      className={`flex items-center w-full rounded-full ${transparentBgChild ? "bg-[transparent]" : "bg-[#211E23]"} ${border && "border-[2px] px-[2px]"}`}
    >
      <div style={childDiv} className={`rounded-full`}></div>
    </div>
  );
};

export { ProgressBar };
