import { styled, css, keyframes } from "styled-components";
import {
  tilesetColsCount,
  tilesetRowsCount,
  tilesetXOffset,
  tilesetYOffset,
  getClipPolygon,
  animationDuration,
  animationFrameCount,
} from "@wop/helpers/TileAnimationHelper";

type StyledStartingNodeProps = {
  readonly $sourceRow: number;
  readonly $clip: string;
  readonly $tileSetInfo: TileSetInfo;
  readonly $frameDuration: number;
  readonly $animationDelay: number;
  readonly $startFrame: number;
  readonly $endFrame: number;
};

const loopKeyframes = (
  tileSetInfo: TileSetInfo,
  startFrame: number,
  endFrame: number
) => keyframes`
from {
  background-position-x: 
    ${tilesetXOffset(tileSetInfo, startFrame)}%;
}
to {
  background-position-x: 
    ${tilesetXOffset(tileSetInfo, endFrame)}%;
}
`;

const StyledStartingNode = styled.div<StyledStartingNodeProps>`
  background-image: ${({ $tileSetInfo }) => `url(${$tileSetInfo.filename})`};
  background-size: ${({ $tileSetInfo }) =>
    `${100 * tilesetColsCount($tileSetInfo)}% ${
      100 * tilesetRowsCount($tileSetInfo)
    }%`};
  background-position-y: ${({ $tileSetInfo, $sourceRow }) =>
    `${tilesetYOffset($tileSetInfo, Number($sourceRow))}%`};
  background-repeat: no-repeat;

  clip-path: ${({ $clip }) => `${getClipPolygon($clip)}`};

  &.completed {
    background-position-x: ${({ $tileSetInfo, $endFrame }) =>
      `${tilesetXOffset($tileSetInfo, $endFrame)}%`};
  }

  &.loopStartNodeAnimation {
    animation: ${({
      $tileSetInfo,
      $startFrame,
      $endFrame,
      $frameDuration,
    }) => css`
      ${loopKeyframes(
        $tileSetInfo,
        $startFrame,
        $endFrame
      )} ${animationDuration(
        $startFrame,
        $endFrame,
        $frameDuration
      )}s steps(${animationFrameCount($startFrame, $endFrame) -
      1}, end) forwards infinite
    `};
  }
  @keyframes loopStartNodeAnimationKfs {
    from {
      background-position-x: ${({ $tileSetInfo, $startFrame }) =>
        `${tilesetXOffset($tileSetInfo, $startFrame)}%`};
    }
    to {
      background-position-x: ${({ $tileSetInfo, $endFrame }) =>
        `${tilesetXOffset($tileSetInfo, $endFrame)}%`};
    }
  }
`;

export { StyledStartingNode };
