import { styled } from "styled-components";
import {
  tilesetColsCount,
  tilesetRowsCount,
  tilesetXOffset,
  tilesetYOffset,
  getClipPolygon,
} from "@wop/helpers/TileAnimationHelper";

type StyledPathProps = {
  readonly $sourceRow: number;
  readonly $clip: string;
  readonly $tileSetInfo: TileSetInfo;
  readonly $frameDuration: number;
  readonly $animationDelay: number;
};

enum PathSpriteState {
  firstFrame = 0,
  lastFrame = 29,
}

function animationFrameCount() {
  return Number(PathSpriteState.lastFrame - PathSpriteState.firstFrame + 1);
}

const StyledPath = styled.div<StyledPathProps>`
  background-image: ${({ $tileSetInfo }) => `url(${$tileSetInfo.filename})`};
  background-size: ${({ $tileSetInfo }) =>
    `${100 * tilesetColsCount($tileSetInfo)}% ${
      100 * tilesetRowsCount($tileSetInfo)
    }%`};
  background-position-y: ${({ $tileSetInfo, $sourceRow }) =>
    `${tilesetYOffset($tileSetInfo, Number($sourceRow))}%`};
  background-repeat: no-repeat;

  clip-path: ${({ $clip }) => `${getClipPolygon($clip)}`};

  &.inactive {
    background-position-x: ${({ $tileSetInfo }) =>
      `${tilesetXOffset($tileSetInfo, Number(PathSpriteState.firstFrame))}%`};
  }

  &.completed {
    background-position-x: ${({ $tileSetInfo }) =>
      `${tilesetXOffset($tileSetInfo, Number(PathSpriteState.lastFrame))}%`};
  }

  &.active {
    background-position-x: ${({ $tileSetInfo }) =>
      `${tilesetXOffset($tileSetInfo, Number(PathSpriteState.firstFrame))}%`};
  }

  &.animatePath {
    animation: ${({ $frameDuration }) =>
      `animatePathKf ${(animationFrameCount() * $frameDuration) / 1000}s steps(${
        animationFrameCount() - 1
      }, end) forwards`};
    animation-delay: ${({ $animationDelay }) => `${$animationDelay}s`};
  }

  @keyframes animatePathKf {
    from {
      background-position-x: ${({ $tileSetInfo }) =>
        `${tilesetXOffset($tileSetInfo, Number(PathSpriteState.firstFrame))}%`};
    }
    to {
      background-position-x: ${({ $tileSetInfo }) =>
        `${tilesetXOffset($tileSetInfo, Number(PathSpriteState.lastFrame))}%`};
    }
  }
`;

export { StyledPath };
