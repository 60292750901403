import { createSlice } from "@reduxjs/toolkit";
import { Auth0ContextInterface, initialContext } from "@auth0/auth0-react";

const initialState = {
  ...(initialContext as Auth0ContextInterface),
  loggingOut: false,
  loggedIn: false,
  currentUserId: "",
  requestId: "",
  requestMetrics: false,
  requestingMetrics: false,
};

const auth0Reducer = createSlice({
  name: "auth0",
  initialState: initialState,
  reducers: {
    setAuthContext: (state, action) => {
      return action.payload;
    },
    setLoggingOut: (state, action) => {
      state.loggingOut = action.payload;
    },
    logout: () => {},
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setRequestId: (state, action) => {
      state.requestId = action.payload;
    },
    setRequestMetrics: (state, action) => {
      state.requestMetrics = action.payload;
    },
    setRequestingMetrics: (state, action) => {
      state.requestingMetrics = action.payload;
    },
    setCurrentUserId: (state, action) => {
      state.currentUserId = action.payload;
    },
  },
});

export const actions = {
  ...auth0Reducer.actions,
};

export default auth0Reducer.reducer;
