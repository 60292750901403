// Default constants

export const DEFAULT_SPRITE_SIZE: Size = {
  width: 96,
  height: 96,
};

export const DEFAULT_TILE_SIZE: Size = {
  width: 96,
  height: 96,
};

export const DEFAULT_NUMBER_ROWS: number = 9;
export const DEFAULT_NUMBER_COLS: number = 20;

// All Item Related
export enum ItemState {
  Normal = 0,
  Active,
  Hover,
}

// Node Item Related

export enum NodeItemType {
  Primary = 0,
  Secondary,
  Tertiary,
}

export enum NodeItemStatus {
  Disabled = 0,
  Enabled,
  Completed,
}

// Path Item Related

export enum PathType {
  //Large paths
  LargeLeftRight = 0,
  // Active half path touching node going from Enabled to Completed
  LargeCross = 1,
  // Half path touches node going from Disabled to Enabled
  _LargeCrossTouchesNewlyEnabled = 2,
  // Completed half path touching node going from Enabled to Completed
  _LargeCrossEnabledAlready = 3,

  // Small paths
  SmallUpDown = 4,
  SmallLeftRight = 5,
  SmallUpLeft = 6,
  SmallUpRight = 7,
  SmallDownLeft = 8,
  SmallDownRight = 9,
  // Active half path touching node going from Enabled to Completed
  SmallCross = 10,
  // Half path touches node going from Disabled to Enabled
  _SmallCrossTouchesNewlyEnabled = 11,
  // Completed half path touching node going from Enabled to Completed
  _SmallCrossEnabledAlready = 12,
}

export enum PathItemStatus {
  Disabled = 0,
  Enabled,
  Completed,
}

// Quest Related
export enum QuestStatus {
  Unavailable = "Unavailable",
  Available = "Available",
  AwaitingVerification = "AwaitingVerification",
  FailedVerification = "FailedVerification",
  Verified = "Verified",
  Completed = "Completed",
}

export enum TaskType {
  TwitterLike = "TwitterLike",
  TwitterFollow = "TwitterFollow",
  TwitterRT = "TwitterRT",
  TwitterPost = "TwitterPost",
  TwitterPostExternal = "TwitterPostExternal",
  TwitterChangeName = "TwitterChangeName",
  TwitterConnect = "TwitterConnect",
  SiweConnect = "SiweConnect",
  DiscordConnect = "DiscordConnect",
  DiscordGetRole = "DiscordGetRole",
  ExternalTask = "ExternalTask",
  WatchYoutube = "WatchYoutube",
  DiscordJoinServer = "DiscordJoinServer",
  ConnectBeaconAccount = "ConnectBeaconAccount",
  OpenLink = "OpenLink",
  PlayMission = "PlayMission",
}

export enum AccountType {
  Siwe = "siwe",
  Twitter = "twitter",
  Discord = "CustomDiscord",
}

export enum SocialProvider {
  Siwe = "Siwe",
  Twitter = "Twitter",
  Discord = "Discord",
}

export enum IntentType {
  Tweet = "tweet",
  User = "user",
  Like = "like",
  Retweet = "retweet",
}

export enum DefaultButtonType {
  Primary = "Primary",
  Secondary = "Secondary",
}

type PathTypeMap = {
  [pathType: string]: PathType;
};

// Dictionaries
export const textToPathType: PathTypeMap = {
  LargeLeftRight: PathType.LargeLeftRight,
  LargeCross: PathType.LargeCross,
  SmallUpDown: PathType.SmallUpDown,
  SmallLeftRight: PathType.SmallLeftRight,
  SmallUpRight: PathType.SmallUpRight,
  SmallUpLeft: PathType.SmallUpLeft,
  SmallDownRight: PathType.SmallDownRight,
  SmallDownLeft: PathType.SmallDownLeft,
  SmallCross: PathType.SmallCross,
};

export enum RankType {
  Novice = "Novice",
  Scout = "Scout",
  Ranger = "Ranger",
  Vanguard = "Vanguard",
  Landseeker = "Landseeker",
}
