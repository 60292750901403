import { DefaultHelmet, Navbar } from "@wop/components";
import styled from "styled-components";

const WeeklyDungeonsRoot = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(24, 23, 26, 0.95),
    rgba(10, 9, 11, 0.95)
  );
  h2 {
    margin-bottom: 0.1rem;
    font-size: 16px;
    font-weight: 600;
    font-family: "Lato-Black";
  }
  ul {
    font-family: "Lato";
    margin-bottom: 2rem;
    > li {
      color: rgb(229 229 229);
    }
  }
  a {
    text-align: center;
    margin: 0 auto;
    span {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: max-content;
      text-align: center;
      align-self: center;
    }
    &.play-now {
      border-radius: 0.5rem;
      display: flex;
      width: 200px;
      text-align: center;
      font-size: 20px;
      padding: 0.75rem 0;
      margin-left: auto;
      margin-right: auto;
      color: #ffffff;
      background-color: hsl(258, 90%, 59%);
      font-family: "CoinageCapsKrugerGray";
      margin-bottom: 1rem;
      transition: background-color 0.3s;
      &:hover {
        background-color: hsl(258, 90%, 63%);
        transition: background-color 0.3s;
      }
    }
    &.founding-collection {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      width: 250px;
      font-family: "AstoriaRoman";
      text-align: center;
      color: hsl(258, 90%, 75%);
      &:hover {
        text-decoration: underline;
      }
      svg {
        margin-top: 2px;
        margin-left: 6px;
      }
    }
  }
`;

const WeeklyDungeons = () => {
  return (
    <div className="flex flex-col gap-10">
      <DefaultHelmet title="New Frontiers Quest | Weekly Dungeons" />
      <Navbar />
      <WeeklyDungeonsRoot className="flex flex-col gap-6 pt-[100px] min-h-screen md:pt-[150px] bg-gradient-to-b">
        <div className="rounded-xl shadow-xl w-11/12 py-6 px-4 sm:px-10 md:w-3/5 xl:w-1/2 2xl:w-2/5 min-[2700px]:w-1/4 mx-auto bg-gradient-to-b to-[#1c1827] from-[#282532]">
          <h1 className="mb-6 text-[28px] leading-8 text-[#f8c173] text-center mx-auto font-[CoinageCapsKrugerGray]">
            Dungeon access and rewards overview
          </h1>
          <div>
            <h2>For Founding Character holders:</h2>
            <ul>
              <li>
                • Each dungeon features a chest at the end with rewards similar
                to event chests, including rare cosmetics.
              </li>
              <li>
                • Each Founding Character can complete each dungeon once and
                claim its prize.
              </li>
              <li>
                • Loot is instantly reflected in The New Frontiers Webapp.
              </li>
            </ul>
          </div>
          <div>
            <h2>For free players: </h2>
            <ul>
              <li>
                • You can also access the Dungeon hub but will receive
                decorative common cosmetics instead of event loot.
              </li>
            </ul>
          </div>
          <div className="mb-10">
            <h2>Weekly Dungeon Release Schedule: </h2>
            <ul>
              <li>
                • Starting tomorrow, 2 dungeons will be available immediately.
              </li>
              <li>
                • Additional dungeons will be released on Wednesday and
                Thursday.
              </li>
              <li>
                • Next Monday, the dungeons for that week will unlock daily from
                Monday to Thursday.
              </li>
            </ul>
          </div>
          <a
            href="https://app.thebeacon.gg"
            target="_blank"
            rel="noreferrer noopener"
            className="play-now"
          >
            <span>Play Now</span>
          </a>
          <a
            href="https://app.treasure.lol/collection/the-beacon-founding-characters"
            className="founding-collection"
            target="_blank"
            rel="noreferrer noopener"
          >
            <span>
              Founding Character Collection{" "}
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.64151 0C9.41698 0 9.24528 0.171698 9.24528 0.396226C9.24528 0.620755 9.41698 0.792453 9.64151 0.792453H12.6529L6.52458 6.92081C6.36609 7.0793 6.36609 7.33014 6.52458 7.47542C6.60383 7.55467 6.70943 7.59434 6.80189 7.59434C6.89434 7.59434 6.99995 7.55467 7.07919 7.47542L13.2075 1.34707V4.35849C13.2075 4.58302 13.3792 4.75472 13.6038 4.75472C13.8283 4.75472 14 4.58302 14 4.35849V0.396226C14 0.171698 13.8283 0 13.6038 0H9.64151ZM1.71698 2.64151C0.766038 2.64151 0 3.40755 0 4.35849V12.283C0 13.234 0.766038 14 1.71698 14H9.64151C10.5925 14 11.3585 13.234 11.3585 12.283V6.33962C11.3585 6.11509 11.1868 5.9434 10.9623 5.9434C10.7377 5.9434 10.566 6.11509 10.566 6.33962V12.283C10.566 12.7981 10.1566 13.2075 9.64151 13.2075H1.71698C1.20189 13.2075 0.792453 12.7981 0.792453 12.283V4.35849C0.792453 3.8434 1.20189 3.43396 1.71698 3.43396H7.66038C7.88491 3.43396 8.0566 3.26226 8.0566 3.03774C8.0566 2.81321 7.88491 2.64151 7.66038 2.64151H1.71698Z"
                  fill="#A886F9"
                />
              </svg>
            </span>
          </a>
        </div>
      </WeeklyDungeonsRoot>
    </div>
  );
};

export { WeeklyDungeons };
