import { levelToRank } from "@wop/utils";
import { ProgressNotification } from "../ProgressNotification";
import {
  Notification,
  NotificationType,
} from "@wop/redux/slices/LevelUpNotificationsSlice";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "@wop/redux";

const NotificationsList = () => {
  const levelUpNotifications = useSelector(
    (state) => (state as RootState).levelUpNotifications.notifications
  );

  function getDescription(
    notificationType: NotificationType,
    notification: Notification
  ) {
    switch (notificationType) {
      case NotificationType.Earned:
        return `+${notification.xp} Prestige`;
      case NotificationType.Level_Up:
        return notification.level
          ? `${levelToRank(parseInt(notification.level))} - Level ${notification.level}`
          : undefined;
      default:
        return undefined;
    }
  }

  return (
    <div className="fixed top-[80px] right-[18px] z-[90]">
      <AnimatePresence>
        {levelUpNotifications.map((notification) => {
          const description = getDescription(notification.type, notification);
          if (!description) return <></>;
          return (
            <div key={notification.id} className="pt-[10px]">
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
              >
                <ProgressNotification
                  id={notification.id}
                  description={description}
                  type={notification.type}
                />
              </motion.div>
            </div>
          );
        })}
      </AnimatePresence>
    </div>
  );
};

export { NotificationsList };
