import { styled } from "styled-components";
import {
  tilesetColsCount,
  tilesetRowsCount,
  tilesetYOffset,
  tilesetXOffset,
  animationFrameCount,
  animationDuration,
} from "@wop/helpers/TileAnimationHelper";

type NodeOverlayAnimationProps = {
  readonly $tileSetInfo: TileSetInfo;
  readonly $startFrame: number;
  readonly $endFrame: number;
  readonly $startLoop: number;
  readonly $endLoop: number;
  readonly $frameDuration: number;
  readonly $sourceRow: number;
  readonly $animationDelay: number;
};

// Not using style component's keyframes` here because
// applying a class which has an animation with a keyframe used
// on the same node previously does not reapply

const NodeOverlay = styled.div<NodeOverlayAnimationProps>`
  pointer-events: none;
  background-image: ${({ $tileSetInfo }) => `url(${$tileSetInfo.filename})`};
  background-size: ${({ $tileSetInfo }) =>
    `${100 * tilesetColsCount($tileSetInfo)}% ${100 * tilesetRowsCount($tileSetInfo)}%`};
  background-position-y: ${({ $tileSetInfo, $sourceRow }) =>
    `${tilesetYOffset($tileSetInfo, $sourceRow)}%`};
  background-repeat: no-repeat;

  &.sequenceAnimation {
    animation: ${({ $startFrame, $endFrame, $frameDuration }) => `
    sequenceAnimationKfs ${animationDuration(
      $startFrame,
      $endFrame,
      $frameDuration
    )}s steps(${animationFrameCount($startFrame, $endFrame) - 1}, end) forwards
    `};
    animation-delay: ${({ $animationDelay }) => `${$animationDelay}s`};
  }

  &.loopAnimation {
    animation: ${({ $startLoop, $endLoop, $frameDuration }) => `
    loopAnimationKfs ${animationDuration($startLoop, $endLoop, $frameDuration)}s steps(${
      animationFrameCount($startLoop, $endLoop) - 1
    }, end) forwards infinite
    `};
  }

  &.sequenceAndLoopAnimation {
    animation: ${({
      $startFrame,
      $endFrame,
      $startLoop,
      $endLoop,
      $frameDuration,
    }) => `
    sequenceAndLoopAnimationSeqKfs ${animationDuration(
      $startFrame,
      $endFrame,
      $frameDuration
    )}s steps(${animationFrameCount($startFrame, $endFrame) - 1}, end) forwards,
      
    sequenceAndLoopAnimationLoopKfs ${animationDuration(
      $startLoop,
      $endLoop,
      $frameDuration
    )}s ${animationDuration($startFrame, $endFrame, $frameDuration)}s steps(${
      animationFrameCount($startLoop, $endLoop) - 1
    }, end) forwards infinite
    `};
  }

  @keyframes sequenceAnimationKfs {
    from {
      background-position-x: ${({ $tileSetInfo, $startFrame }) =>
        `${tilesetXOffset($tileSetInfo, $startFrame)}%`};
    }
    to {
      background-position-x: ${({ $tileSetInfo, $endFrame }) =>
        `${tilesetXOffset($tileSetInfo, $endFrame)}%`};
    }
  }

  @keyframes loopAnimationKfs {
    from {
      background-position-x: ${({ $tileSetInfo, $startLoop }) =>
        `${tilesetXOffset($tileSetInfo, $startLoop)}%`};
    }
    to {
      background-position-x: ${({ $tileSetInfo, $endLoop }) =>
        `${tilesetXOffset($tileSetInfo, $endLoop)}%`};
    }
  }

  @keyframes sequenceAndLoopAnimationSeqKfs {
    from {
      background-position-x: ${({ $tileSetInfo, $startFrame }) =>
        `${tilesetXOffset($tileSetInfo, $startFrame)}%`};
    }
    to {
      background-position-x: ${({ $tileSetInfo, $endFrame }) =>
        `${tilesetXOffset($tileSetInfo, $endFrame)}%`};
    }
  }

  @keyframes sequenceAndLoopAnimationLoopKfs {
    from {
      background-position-x: ${({ $tileSetInfo, $startLoop }) =>
        `${tilesetXOffset($tileSetInfo, $startLoop)}%`};
    }
    to {
      background-position-x: ${({ $tileSetInfo, $endLoop }) =>
        `${tilesetXOffset($tileSetInfo, $endLoop)}%`};
    }
  }
`;

export { NodeOverlay };
