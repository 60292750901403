import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export enum NotificationType {
  Earned = "Earned",
  Level_Up = "LevelUp",
}

export type Notification = {
  id: string;
  xp?: number;
  level?: string;
  type: NotificationType;
};

type NotificationState = {
  lastNotification: string;
  notifications: Notification[];
};

const initialState = {
  lastNotification: "",
  notifications: [],
} as NotificationState;

const levelUpNotificationsSlice = createSlice({
  name: "levelUpNotifications",
  initialState,
  reducers: {
    addNotification(state, action) {
      const { xp, level, type } = action.payload;
      const id = uuidv4();
      state.notifications.push({ id, xp, level, type });
      state.lastNotification = id;
    },
    removeNotification(state, action) {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
  },
});

export const levelUpNotificationsActions = {
  ...levelUpNotificationsSlice.actions,
};

export default levelUpNotificationsSlice;
