import { Routes, Route } from "react-router-dom";
import {
  // Home,
  NotFound,
  // Progress,
  // Referrals,
  // DailyQuests,
  Chests,
  Login,
  // WeeklyDungeons,
} from "@wop/pages";
import { ProtectedRoutes } from "./protectedRouteWrapper";
// import { GameStub } from "@wop/pages/GameStub";
// import { SplashGate } from "@wop/components/SplashGate";

const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoutes>
              {/* <SplashGate> */}
              {/* <Home /> */}
              <Chests />
              {/* </SplashGate> */}
            </ProtectedRoutes>
          }
        />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/progress/:shareId" element={<Progress />} />
        <Route
          path="/referrals"
          element={
            <ProtectedRoutes>
              <Referrals />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/weekly-dungeons"
          element={
            <ProtectedRoutes>
              <WeeklyDungeons />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/daily"
          element={
            <ProtectedRoutes>
              <DailyQuests />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/chests"
          element={
            <ProtectedRoutes>
              <Chests />
            </ProtectedRoutes>
          }
        />
        <Route path="/gamestub/:missionId" element={<GameStub />} /> */}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export { AppRouter };
