import React, { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { RootState } from "@wop/redux";
import {
  useGetUserEventQuery,
  writeOfPassageApi,
} from "@wop/redux/services/api";
import { getUserPayload } from "@wop/utils";
import { useDispatch, useSelector } from "react-redux";
import { Loading, Login } from "@wop/pages";
import { usePreLoadElements } from "@wop/hooks";
import { soundActions } from "@wop/redux/slices";

type ProtectedRoutesWrapperProps = {
  children: React.ReactNode;
};

const ProtectedRoutes = ({ children }: ProtectedRoutesWrapperProps) => {
  const auth0 = useSelector((state) => (state as RootState).auth0);
  const { user, isLoading: isLoadingAuth0, isAuthenticated } = auth0;
  const dispatch = useDispatch();

  const userPayload = getUserPayload(user);
  const {
    data: userResponse,
    isLoading: isLoadingGetUser,
    isUninitialized: isGetUserUninitialized,
  } = writeOfPassageApi.endpoints.getUser.useQueryState(
    userPayload ?? skipToken
  );

  const { data: userEventResponse, isLoading: isEventLoading } =
    useGetUserEventQuery(
      !userResponse?.data.id || !userResponse?.data.currentEventId
        ? skipToken
        : {
            userId: userResponse.data.id,
            eventId: userResponse.data.currentEventId,
          }
    );

  const { progress: progressElements, isLoaded: isElementsLoaded } =
    usePreLoadElements();

  useEffect(() => {
    dispatch(soundActions.overrideMusic(false));

    return () => {
      dispatch(soundActions.overrideMusic(true));
    };
  }, [dispatch]);

  if (auth0.loggingOut && isLoadingAuth0)
    return <Loading progressPercent={0} />;
  if (
    isAuthenticated &&
    (isGetUserUninitialized ||
      isLoadingGetUser ||
      isEventLoading ||
      !isElementsLoaded)
  )
    return <Loading progressPercent={progressElements} />;

  if (userEventResponse && auth0.loggedIn) {
    return children;
  }

  return <Login />;
};

export { ProtectedRoutes };
