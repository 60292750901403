import { textToNewFrontiersDropImage } from "@wop/helpers/ChestHelper";
import { useState } from "react";
import {
  useHover,
  useInteractions,
  useFloating,
  autoUpdate,
  flip,
} from "@floating-ui/react";
import { Rarity, lootItemsByKind } from "@wop/data/lootItems";

type ChestItemProps = {
  kind: string;
  quantity: number;
  label: string;
};

const rarityStyles = {
  [Rarity.Common]: "bg-[#242424]/40 border-2 border-[#242424]",
  [Rarity.Rare]: "bg-[#1a1f29]/40 border-2 border-[#1a1f29]",
  [Rarity.Epic]: "bg-[#261e2e]/40 border-2 border-[#261e2e]",
  [Rarity.Legendary]: "bg-[#292317]/40 border-2 border-[#292317]",
  [Rarity.Mythical]: "bg-[#2a1f1f]/40 border-2 border-[#2a1f1f]",
};
const rarityStylesActive = {
  [Rarity.Common]: "bg-[#242424] border-2 border-[#515151]",
  [Rarity.Rare]: "bg-[#1a2740] border-2 border-[#334e84]",
  [Rarity.Epic]: "bg-[#261e2e] border-2 border-[#4f3965]",
  [Rarity.Legendary]: "bg-[#3c2d0b] border-2 border-[#6e590e]",
  [Rarity.Mythical]: "bg-[#311919] border-2 border-[#673434]",
};
const rarityTextColors = {
  [Rarity.Common]: "#CCCCCC",
  [Rarity.Rare]: "#5f93fb",
  [Rarity.Epic]: "#a866e9",
  [Rarity.Legendary]: "#d3971d",
  [Rarity.Mythical]: "#e64e4e",
};

const ChestItem = ({ kind, quantity, label }: ChestItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom",
    middleware: [flip()],
    open: false,
    onOpenChange: (open, event) => {
      if (event?.type == "mouseenter") {
        setIsOpen(true);
      } else if (event?.type == "mouseleave") {
        setIsOpen(false);
      }
    },
    whileElementsMounted: autoUpdate,
  });
  const hover = useHover(context);
  const { getFloatingProps } = useInteractions([hover]);
  const item = lootItemsByKind[kind];
  const itemRarity = item?.rarity || Rarity.Common;

  return (
    <>
      <div
        ref={refs.setReference}
        className={`w-[90px] h-[90px] flex flex-row justify-center items-center ${quantity <= 0 ? rarityStyles[itemRarity] : rarityStylesActive[itemRarity]} rounded-md relative`}
      >
        {quantity > 0 && (
          <div className="absolute bottom-0 right-[5px]">
            <p className="font-[AstoriaRoman] text-[20px] select-none">
              x{quantity}
            </p>
          </div>
        )}
        <div className="self-center">
          <img
            className={`${quantity <= 0 ? "grayscale opacity-[0.24]" : ""} render-pixelated select-none`}
            src={textToNewFrontiersDropImage[kind]}
            alt="Chest Image"
          />
        </div>
      </div>
      {isOpen && (
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          style={{
            ...floatingStyles,
            zIndex: 70,
            pointerEvents: "none",
            color: rarityTextColors[itemRarity],
          }}
        >
          <div className="bg-black rounded-md pl-[10px] pr-[10px] pt-[5px]">
            <p className="font-[AstoriaRoman] leading-normal text-[14px] select-none">
              {label}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export { ChestItem };
