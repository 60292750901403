import { User as Auth0User } from "@auth0/auth0-react";

type UserInfoProps = {
  picture?: string;
  auth0User: Auth0User;
};

const UserInfo = ({ picture, auth0User }: UserInfoProps) => {
  const AUTH0_ACTION_NAMESPACE = import.meta.env.VITE_AUTH0_ACTION_NAMESPACE;
  const username = auth0User?.[`${AUTH0_ACTION_NAMESPACE}/username`];

  return (
    <div className="flex">
      <div className="flex-1 flex items-center">
        {picture && (
          <div className="px-4">
            <img
              src={picture}
              alt="avatar"
              className="inline-block h-6 w-6 rounded-full ring-1 ring-white"
            />
          </div>
        )}
        <div className="flex-1 text-[18px] xl:text-[22px]">
          <p>{username ? `@${username}` : ""}</p>
        </div>
      </div>
    </div>
  );
};

export { UserInfo };
