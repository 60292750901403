import beaconImageUrl from "../../assets/images/prelude-notification/beacon.png";

const PreludeNotification = () => {
  return (
    <a
      href="https://skillcap.itch.io/the-beacon-story-prelude"
      target="_blank"
      rel="noopener noreferrer"
      className={`w-[210px] h-[100px] flex flex-row items-center align-middle justify-center gap-4 bg-[hsla(22,2%,4%,1)] border-[3px] border-[hsla(22,5%,20%,1)] border-r-[0px] rounded-3xl rounded-r-none cursor-pointer select-none`}
    >
      <img
        className=" render-pixelated -ml-2 -mt-1.5"
        src={beaconImageUrl}
        alt="beacon image"
      />
      <p className="text-[18px] text-center self-center leading-6 mt-1 font-bold font-[AstoriaRoman] text-[hsla(22,10%,100%,1)]">
        TRY THE <br /> PRELUDE
      </p>
    </a>
  );
};

export { PreludeNotification };
