import { useEffect } from "react";
import { RootState } from "@wop/redux";
import { writeOfPassageApi } from "@wop/redux/services/api";
import { useDispatch, useSelector } from "react-redux";
import { getUserPayload } from "@wop/utils";
import * as Sentry from "@sentry/react";
import { actions, taskActions } from "@wop/redux/slices";
import { AlertModalActions } from "@wop/redux/slices/AlertModalSlice";
import { useFetchMetrics } from "../../hooks/FetchMetrics";

const Auth0Callback = () => {
  const auth0 = useSelector((state) => (state as RootState).auth0);

  const { user, error: auth0Error, logout: auth0Logout } = auth0;

  const [getUser] = writeOfPassageApi.endpoints.getUser.useLazyQuery();

  const dispatch = useDispatch();

  useFetchMetrics();

  useEffect(() => {
    async function userLogin() {
      if (user?.nickname) {
        const userPayload = getUserPayload(user);
        if (userPayload) {
          try {
            const currentUser = await getUser(userPayload).unwrap();

            dispatch(actions.setRequestId(currentUser.data.metricsRequestId));
            dispatch(actions.setCurrentUserId(currentUser.data.id));

            dispatch(actions.setLoggedIn(true));
            await dispatch(taskActions.resetBlockRevealAnimation());
            Sentry.setUser({ userid: currentUser.data.id });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (err: any) {
            Sentry.addBreadcrumb({
              message: `GetUser error code: ${err.status}`,
              level: "error",
            });
            Sentry.captureException(err);
            if (err.status) {
              console.log(`Please try again later... (E.${err.status})`);
            } else {
              console.log("Please try again later... (Login Required)");
            }
            await dispatch(actions.setLoggingOut(true));
            await auth0Logout({
              logoutParams: {
                returnTo: `${window.location.origin}/login`,
              },
            });
            Sentry.setUser(null);
            dispatch(actions.setLoggedIn(false));
          }
        }
      }
    }
    userLogin();
  }, [user, getUser, auth0Logout, dispatch]);

  useEffect(() => {
    if (auth0Error) {
      const errorMessage = auth0Error?.message;
      console.log(errorMessage);
      if (errorMessage === "TWITTER_DOES_NOT_QUALIFY") {
        dispatch(
          AlertModalActions.show({
            title: "Your account does not qualify to participate.",
            legend: {
              text: "How can I qualify?",
              url: "https://discord.gg/thebeacon",
            },
            actionButton: () => {
              auth0Logout({
                logoutParams: {
                  returnTo: `${window.location.origin}/login`,
                },
              }).then(() => {
                AlertModalActions.hide();
                Sentry.setUser(null);
              });
            },
          })
        );
      } else {
        dispatch(actions.setLoggingOut(true));
        auth0Logout({
          logoutParams: {
            returnTo: `${window.location.origin}/login`,
          },
        }).then(() => {
          Sentry.setUser(null);
        });
      }
    }
  }, [auth0Error, auth0Logout, dispatch]);

  return <></>;
};

export { Auth0Callback };
