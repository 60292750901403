import { DefaultButton } from "@wop/components";
import { DefaultButtonType } from "@wop/common";

type AlertModalProps = {
  title: string;
  actionButton: () => void;
  legend?: {
    text: string;
    url: string;
  };
};

const AlertModal = ({ title, legend, actionButton }: AlertModalProps) => {
  return (
    <div className="fixed top-0 w-[100vw] h-[100vh] z-[110] bg-[rgba(0,0,0,0.7)] flex justify-center items-center select-none">
      <div className="bg-black p-[25px] flex flex-col justify-center items-center rounded-xl border-white border-[1px]">
        <h3 className="font-[AstoriaRoman] text-[20px] flex-1 text-center">
          {title}
        </h3>
        <div className="flex-1 w-[120px] pt-[20px]">
          <DefaultButton
            title="Ok"
            type={DefaultButtonType.Primary}
            onClick={() => {
              actionButton();
            }}
          />
        </div>

        {legend?.text && legend?.url && (
          <div className="flex flex-1 pt-[18px]">
            <a
              className="font-[AstoriaRoman] text-[15px] text-[#cf9145]"
              href={legend.url}
              target="_blank"
            >
              {legend.text}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export { AlertModal };
