import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import App from "./App.tsx";
import "./index.css";
import { store } from "@wop/redux";
import { Provider } from "react-redux";
import { Auth0MultiProvider, Auth0Wrapper } from "@wop/auth0";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { FingerprintProvider } from "@wop/fingerprint";

posthog.init(import.meta.env.VITE_POSTHOG, {
  api_host: "https://app.posthog.com",
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Ignore exceptions thrown by extensions and rejected promises
  ignoreErrors: [
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
    "Object captured as exception",
    "Object captured as promise rejection",
    "Non-Error promise rejection captured with value",

    "Minified Redux Toolkit error #38",

    "Importing a module script failed",
    "error loading dynamically imported module",
    "Failed to fetch dynamically imported module",

    "Failed to start the audio device",

    "Talisman extension has not been configured yet",
    "Can't find variable: zaloJSV2",
    "can't redefine non-configurable property",
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /https:\/\/wop\.devmod\.xyz/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <FingerprintProvider>
      <HelmetProvider>
        <Auth0MultiProvider>
          <Provider store={store}>
            <Auth0Wrapper>
              <App />
            </Auth0Wrapper>
          </Provider>
        </Auth0MultiProvider>
      </HelmetProvider>
    </FingerprintProvider>
  </React.StrictMode>
);
