import { styled, css, keyframes } from "styled-components";
import {
  tilesetColsCount,
  tilesetRowsCount,
  tilesetXOffset,
  tilesetYOffset,
  animationFrameCount,
  animationDuration,
} from "@wop/helpers/TileAnimationHelper";

type NodeAnimationProps = {
  readonly $tileSetInfo: TileSetInfo;
  readonly $frameDuration: number;
  readonly $animationDelay: number;
  readonly $sourceRow: number;
};

enum NodeSpriteState {
  Disabled = 0,
  EnabledFirstFrame = 1,
  Enabled = 23,
  CompletedFirstFrame = 24,
  Completed = 53,
}

const sequenceKeyframes = (
  tileSetInfo: TileSetInfo,
  startFrame: number,
  endFrame: number
) => keyframes`
from {
  background-position-x: 
    ${tilesetXOffset(tileSetInfo, startFrame)}%;
}
to {
  background-position-x: 
    ${tilesetXOffset(tileSetInfo, endFrame)}%;
}
`;

const StyledNode = styled.div<NodeAnimationProps>`
  background-image: ${({ $tileSetInfo }) => `url(${$tileSetInfo.filename})`};
  background-size: ${({ $tileSetInfo }) =>
    `${100 * tilesetColsCount($tileSetInfo)}% ${
      100 * tilesetRowsCount($tileSetInfo)
    }%`};

  background-position-y: ${({ $tileSetInfo, $sourceRow }) =>
    `${tilesetYOffset($tileSetInfo, $sourceRow)}%`};
  background-repeat: no-repeat;

  &.disabled {
    background-position-x: ${({ $tileSetInfo }) =>
      `${tilesetXOffset($tileSetInfo, Number(NodeSpriteState.Disabled))}%`};
  }
  &.enabled {
    background-position-x: ${({ $tileSetInfo }) =>
      `${tilesetXOffset($tileSetInfo, Number(NodeSpriteState.Enabled))}%`};
  }
  &.completed {
    background-position-x: ${({ $tileSetInfo }) =>
      `${tilesetXOffset($tileSetInfo, Number(NodeSpriteState.Completed))}%`};
  }
  &.hidden {
    display: none;
  }

  &.animateEnabled {
    animation: ${({ $tileSetInfo, $frameDuration }) => css`
      ${sequenceKeyframes(
        $tileSetInfo,
        Number(NodeSpriteState.EnabledFirstFrame),
        Number(NodeSpriteState.Enabled)
      )} ${animationDuration(
        NodeSpriteState.EnabledFirstFrame,
        NodeSpriteState.Enabled,
        $frameDuration
      )}s steps(${animationFrameCount(
        NodeSpriteState.EnabledFirstFrame,
        NodeSpriteState.Enabled
      ) - 1}, end) forwards
    `};
    animation-delay: ${({ $animationDelay }) => `${$animationDelay}s`};
  }

  &.animateCompleted {
    animation: ${({ $tileSetInfo, $frameDuration }) => css`
      ${sequenceKeyframes(
        $tileSetInfo,
        Number(NodeSpriteState.CompletedFirstFrame),
        Number(NodeSpriteState.Completed)
      )} ${animationDuration(
        NodeSpriteState.CompletedFirstFrame,
        NodeSpriteState.Completed,
        $frameDuration
      )}s steps(${animationFrameCount(
        NodeSpriteState.CompletedFirstFrame,
        NodeSpriteState.Completed
      ) - 1}, end) forwards
    `};
  }
`;

export { StyledNode };
