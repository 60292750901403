import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  legend: {
    text: "",
    url: "",
  },
  actionButton: () => {},
  showModal: false,
};

const AlertModalSlice = createSlice({
  name: "AlertModal",
  initialState: initialState,
  reducers: {
    show: (state, action) => {
      state.title = action.payload.title;
      state.legend = action.payload.legend;
      state.actionButton = action.payload.actionButton;
      state.showModal = true;
    },
    hide: (state) => {
      state.title = "";
      state.legend = { text: "", url: "" };
      state.actionButton = () => {};
      state.showModal = false;
    },
  },
});

export const AlertModalActions = {
  ...AlertModalSlice.actions,
};

export default AlertModalSlice;
