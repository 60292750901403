import { combineReducers } from "@reduxjs/toolkit";
import { writeOfPassageApi } from "@wop/redux/services/api";
import { BeaconApi } from "@wop/redux/services/beaconApi";
import auth0Reducer from "@wop/redux/slices/AuthSlice";
import TaskReducer from "@wop/redux/slices/TaskSlice";
import SoundSlice from "@wop/redux/slices/SoundSlice";
import AppSlice from "@wop/redux/slices/AppSlice";
import ChestSlice from "../slices/ChestSlice";
import levelUpNotificationsSlice from "../slices/LevelUpNotificationsSlice";
import AlertModalSlice from "../slices/AlertModalSlice";

const combinedReducer = combineReducers({
  [writeOfPassageApi.reducerPath]: writeOfPassageApi.reducer,
  [BeaconApi.reducerPath]: BeaconApi.reducer,
  auth0: auth0Reducer,
  task: TaskReducer,
  chest: ChestSlice.reducer,
  levelUpNotifications: levelUpNotificationsSlice.reducer,
  sound: SoundSlice.reducer,
  app: AppSlice.reducer,
  alertModal: AlertModalSlice.reducer,
});

const rootReducer = (
  state: ReturnType<typeof combinedReducer> | undefined,
  action: { type: string }
) => {
  if (action.type === "auth0/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export { rootReducer };
