import { useEffect } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { writeOfPassageApi } from "@wop/redux/services/api";
import { RootState } from "@wop/redux";
import { getUserPayload } from "@wop/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertModal } from "@wop/components";
import { Loading } from "@wop/pages";
import loginLogo from "@wop/assets/images/login/nfq-phase2-logo.png";
import comingSoonGlow from "@wop/assets/images/login/coming-soon-glow.png";
import twitterIcon from "@wop/assets/images/login/woops.png";
import bgImage from "@wop/assets/images/login/bg.png";
import discordIcon from "@wop/assets/images/login/discord-icon.png";
import xIcon from "@wop/assets/images/login/x-icon.png";
import { CheckYourLootButton } from "@wop/components/CheckYourLootButton";
import { PreludeNotification } from "@wop/components/PreludeNotification";

const shouldShowTwitterLoginDisclaimer = false;

const Login = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const referral = params.get("referral");

  const auth0 = useSelector((state) => (state as RootState).auth0);
  const showAlertModal = useSelector(
    (state) => (state as RootState).alertModal.showModal
  );
  const titleAlertModal = useSelector(
    (state) => (state as RootState).alertModal.title
  );
  const legendAlertModal = useSelector(
    (state) => (state as RootState).alertModal.legend
  );
  const actionButtonAlertModal = useSelector(
    (state) => (state as RootState).alertModal.actionButton
  );
  const { user, isLoading: isLoadingAuth0 } = auth0;

  const userPayload = getUserPayload(user);
  const { data: userResponse, isLoading: isLoadingGetUser } =
    writeOfPassageApi.endpoints.getUser.useQueryState(userPayload ?? skipToken);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoadingGetUser && userResponse) navigate("/");
  }, [userResponse, navigate, isLoadingGetUser]);

  if (!userResponse && !isLoadingAuth0 && !isLoadingGetUser)
    return (
      <div
        className="flex flex-col min-h-screen p-4 sm:p-12  bg-[#020512]"
        style={{
          backgroundImage: `url("${bgImage}")`,
          backgroundRepeat: `repeat`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="mb-20 mx-auto sm:mx-0">
          <img src={loginLogo} className="object-contain w-[200px]" />
        </div>
        <div className="flex flex-col mx-auto items-center min-h-[600px] lg:min-w-[640px]">
          <h2 className="text-center  text-[hsla(22,10%,100%,1)] font-[CoinageCapsKrugerGray] leading-3 text-[22px]">
            Phase II
          </h2>
          <h1 className="text-center text-[hsla(22,10%,100%,1)] font-[CoinageCapsKrugerGray] mt-5 leading-8 sm:leading-normal sm:mt-1 mb-3 text-[36px] md:text-[52px]">
            Trials and Tribulations
          </h1>
          <div
            className="h-14"
            style={{
              backgroundImage: `url(${comingSoonGlow})`,
              backgroundRepeat: `no-repeat`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <p
              className="text-center mt-2 font-[CoinageCapsKrugerGray] text-[24px]"
              style={{
                textShadow:
                  "0 0 5px #bf4807, 0 0 12px #bf4807, 0 0 14px #bf4807",
              }}
            >
              Coming Soon
            </p>
          </div>
          <p className="text-center flex flex-col gap-3 font-[Lato] text-[18px] mt-10 tracking-[0.05em] leading-[20px]">
            <span>
              Phase 2 will bring an exciting set of new features and challenges,{" "}
              <br />
              stay tuned to our announcements on what's next to come!
            </span>
            <span>
              We're excited for what's next and thrilled to have you on this
              journey with us!
            </span>
          </p>
          <div className="pt-[40px] lg:pt-[80px] pb-[150px] sm-[70px]">
            <CheckYourLootButton />
            {referral && (
              <div>
                <p className="pt-[10px] text-center font-[Lato] text-[14px]">{`Referral code: ${referral}`}</p>
              </div>
            )}
          </div>
          {shouldShowTwitterLoginDisclaimer && (
            <div className="bg-[#150b0e87] drop-shadow-md border border-red-600/20 px-2 pr-8 rounded-md text-red-400 flex items-center font-[Lato]">
              <img
                src={twitterIcon}
                alt="Icon"
                className="mr-2 w-[142px] h-[136px]"
                style={{ imageRendering: "pixelated" }}
              />
              <div className="ml-4">
                <p className="text-lg font-bold">
                  Twitter login is experiencing temporary problems
                </p>
                <p className="text-sm text-red-400/80">
                  Try again later, we should be back to normal shortly!
                </p>
              </div>
            </div>
          )}
        </div>
        {showAlertModal && (
          <AlertModal
            title={titleAlertModal}
            actionButton={actionButtonAlertModal}
            legend={legendAlertModal}
          />
        )}
        <div className="flex flex-col mt-auto font-[Lato] text-[18px] gap-2 items-center align-middle justify-center">
          <p>Stay up to date</p>
          <div className="flex gap-2">
            <a
              href="https://x.com/The_Beacon_GG"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={xIcon} alt="x" className="w-[48px] h-[48px]" />
            </a>
            <a
              href="https://discord.gg/thebeacon"
              target="_blank"
              rel="noopener noreferrer"
              className="-mt-px"
            >
              <img src={discordIcon} alt="x" className="w-[52px] h-[52px]" />
            </a>
          </div>
        </div>
        <div className="fixed bottom-[100px] z-[90] right-[0px] hidden lg:block">
          <PreludeNotification />
        </div>
      </div>
    );

  return auth0.loggingOut && <Loading />;
};

export { Login };
