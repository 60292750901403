import { useEffect, useRef } from "react";
import { styled } from "styled-components";
import {
  tilesetColsCount,
  tilesetRowsCount,
  tilesetYOffset,
  tilesetXOffset,
  animationFrameCount,
  animationDuration,
} from "@wop/helpers/TileAnimationHelper";

type ScreenflashAnimationProps = {
  readonly $tileSetInfo: TileSetInfo;
  readonly $startFrame: number;
  readonly $endFrame: number;
  readonly $frameDuration: number;
  readonly $sourceRow: number;
  readonly $animationDelay: number;
};

const ScreenflashDiv = styled.div<ScreenflashAnimationProps>`
  pointer-events: none;
  background-image: ${({ $tileSetInfo }) => `url(${$tileSetInfo.filename})`};
  background-size: ${({ $tileSetInfo }) =>
    `${100 * tilesetColsCount($tileSetInfo)}% ${
      100 * tilesetRowsCount($tileSetInfo)
    }%`};
  background-position-y: ${({ $tileSetInfo, $sourceRow }) =>
    `${tilesetYOffset($tileSetInfo, $sourceRow)}%`};
  background-repeat: no-repeat;
  &.screenflashAnimation {
    animation: ${({ $startFrame, $endFrame, $frameDuration }) => `
    screenflashAnimationKfs ${animationDuration(
      $startFrame,
      $endFrame,
      $frameDuration
    )}s steps(${animationFrameCount($startFrame, $endFrame) - 1}, end) forwards
    `};
    animation-delay: ${({ $animationDelay }) => `${$animationDelay}s`};
  }

  @keyframes screenflashAnimationKfs {
    from {
      background-position-x: ${({ $tileSetInfo, $startFrame }) =>
        `${tilesetXOffset($tileSetInfo, $startFrame)}%`};
    }
    to {
      background-position-x: ${({ $tileSetInfo, $endFrame }) =>
        `${tilesetXOffset($tileSetInfo, $endFrame)}%`};
    }
  }
`;

const screenFlashTs = {
  filename: "screen-flash.png",
  size: { width: 960, height: 32 },
  tileSize: { width: 32, height: 32 },
};

const Screenflash = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const div = ref.current;
    div?.addEventListener("animationend", removeFlashAnimation);

    return () => {
      div?.removeEventListener("animationend", removeFlashAnimation);
    };
  }, []);

  function removeFlashAnimation() {
    ref.current?.classList.remove("screenflashAnimation");
  }

  return (
    <ScreenflashDiv
      id="screenflash"
      style={{
        zIndex: "120",
        top: "0",
        left: "0",
        height: `100vh`,
        width: `100vw`,
        position: "fixed",
      }}
      ref={ref}
      $tileSetInfo={screenFlashTs}
      $frameDuration={83}
      $startFrame={0}
      $endFrame={29}
      $sourceRow={0}
      $animationDelay={0}
    />
  );
};
export { Screenflash };
