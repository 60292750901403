import { configureStore } from "@reduxjs/toolkit";
import { writeOfPassageApi } from "@wop/redux/services/api";
import { BeaconApi } from "@wop/redux/services/beaconApi";
import { rootReducer } from "@wop/redux/reducers";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(writeOfPassageApi.middleware, BeaconApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: { writeOfPassageApi: ..., BeaconApi: ..., auth0: ..., task: ... }
export type AppDispatch = typeof store.dispatch;
