import { DefaultButtonType } from "@wop/common";

type DefaultButtonProps = {
  title: string;
  type?: DefaultButtonType;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
};

const DefaultButton = ({
  title,
  type = DefaultButtonType.Primary,
  onClick,
  disabled = false,
  className = "",
}: DefaultButtonProps) => {
  function getStyleByType() {
    switch (type) {
      case DefaultButtonType.Secondary:
        return "border-2 border-[rgb(240,157,47)] text-[rgb(240,157,47)]";
      case DefaultButtonType.Primary:
      default:
        return "bg-[rgb(240,157,47)] text-black";
    }
  }
  return (
    <button
      className={`w-full px-[18px] rounded-lg font-[CoinageCapsKrugerGray] text-12 ${getStyleByType()} ${className} ${disabled ? "grayscale cursor-default" : ""}`}
      onClick={() => {
        if (!disabled && onClick) onClick();
      }}
    >
      {title}
    </button>
  );
};

export { DefaultButton };
