import { Rarity } from "@wop/data/lootItems";
import { getFormatDateChestItem } from "@wop/helpers/ChestHelper";

type ChestListItemProps = {
  label: string;
  date?: Date;
  rarity: Rarity;
};

const rarityColors = {
  [Rarity.Common]: "#b3b3b3",
  [Rarity.Rare]: "#80b6fc",
  [Rarity.Epic]: "#c29af4",
  [Rarity.Legendary]: "#e6b84b",
  [Rarity.Mythical]: "#ff6f6f",
};

const ChestListItem = ({ label, date, rarity }: ChestListItemProps) => {
  const color = rarityColors[rarity];

  return (
    <div className={`flex bg-[#1E1E1E] px-[15px] pt-[5px] rounded-lg`}>
      <div className="flex flex-1 content-center">
        <p
          className={`font-[AstoriaRoman] text-[18px] text-left`}
          style={{ color }}
        >
          {label}
        </p>
      </div>
      {date && (
        <div className="flex content-center">
          <p className="font-[AstoriaRoman] text-[#b2b2b7] text-[16px] text-right">
            {getFormatDateChestItem(date)}
          </p>
        </div>
      )}
    </div>
  );
};
export { ChestListItem };
