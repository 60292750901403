import { ReactElement } from "react";
import {
  FpjsProvider,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-react";

const metricsApiKey = import.meta.env.VITE_METRICS_API_KEY;
const metricsBaseUrl = import.meta.env.VITE_METRICS_BASE_URL;

type Props = {
  children: ReactElement;
};

const FingerprintProvider = ({ children }: Props) => {
  return (
    <FpjsProvider
      loadOptions={{
        apiKey: metricsApiKey,
        endpoint: [metricsBaseUrl, FingerprintJSPro.defaultEndpoint],
        scriptUrlPattern: [
          `${metricsBaseUrl}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
          FingerprintJSPro.defaultScriptUrlPattern,
        ],
      }}
    >
      {children}
    </FpjsProvider>
  );
};

export { FingerprintProvider };
