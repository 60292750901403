import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questId: "",
  open: false,
  videoId: "",
  videoWatched: false,

  openBeaconLogin: false,
  beaconLoginCompleted: false,

  openBeaconClient: false,
  beaconClientCompleted: false,
  beaconClientQuestId: "",
  beaconClientMissionId: "",

  openBeaconLoot: false,
  beaconLootCompleted: false,

  blockRevealAnimation: false,
};

const TaskReducer = createSlice({
  name: "task",
  initialState: initialState,
  reducers: {
    showTask: (state, action) => {
      const videoId = new URL(action.payload.url).searchParams.get("v") || "";
      state.questId = action.payload.questId;
      state.open = true;
      state.videoId = videoId;
    },
    hideTask: (state) => {
      state.questId = "";
      state.open = false;
      state.videoId = "";
    },

    // YoutubeQuest
    setVideoWatchedSuccess: (state) => {
      state.videoWatched = true;
    },
    setVideoWatchedFail: (state) => {
      state.videoWatched = false;
    },

    // Beacon Login
    showBeaconLogin: (state) => {
      state.openBeaconLogin = true;
    },
    hideBeaconLogin: (state) => {
      state.openBeaconLogin = false;
    },
    setBeaconLoginSuccess: (state) => {
      state.beaconLoginCompleted = true;
    },
    setBeaconLoginFail: (state) => {
      state.beaconLoginCompleted = false;
    },

    // Game Client
    resetBeaconClient: (state) => {
      state.beaconClientCompleted = false;
      state.beaconClientQuestId = "";
      state.beaconClientMissionId = "";
      state.openBeaconClient = false;
    },
    showBeaconClient: (state, action) => {
      state.beaconClientCompleted = false;
      state.beaconClientQuestId = action.payload.questId;
      state.beaconClientMissionId = action.payload.missionId;
      state.openBeaconClient = true;
    },
    hideBeaconClient: (state) => {
      state.openBeaconClient = false;
    },
    setBeaconClientSuccess: (state) => {
      state.beaconClientCompleted = true;
    },
    setBeaconClientFail: (state) => {
      state.beaconClientCompleted = false;
    },

    // Loot Client
    showBeaconLoot: (state) => {
      state.openBeaconLoot = true;
    },
    hideBeaconLoot: (state) => {
      state.openBeaconLoot = false;
    },
    setBeaconLootSuccess: (state) => {
      state.beaconLootCompleted = true;
    },
    setBeaconLootFail: (state) => {
      state.beaconLootCompleted = false;
    },

    // RevealAnimation
    resetBlockRevealAnimation: (state) => {
      state.blockRevealAnimation = false;
    },
    blockRevealAnimation: (state) => {
      state.blockRevealAnimation = true;
    },
  },
});

export const taskActions = {
  ...TaskReducer.actions,
};

export default TaskReducer.reducer;
