import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  splashGatePassed: false,
};

const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSplashGatePassed: (state) => {
      state.splashGatePassed = true;
    },
  },
});

export const appActions = {
  ...AppSlice.actions,
};

export default AppSlice;
