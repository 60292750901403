type ClipPointsDictionary = {
  [key: string]: Array<string>;
};
const textToClipPoints: ClipPointsDictionary = {
  up: ["0% 0%", "100% 0%", "50% 50%"],
  down: ["0% 100%", "100% 100%", "50% 50%"],
  left: ["0% 0%", "0% 100%", "50% 50%"],
  right: ["100% 0%", "100% 100%", "50% 50%"],
  none: [],
};

export function getClipPolygon(section: string) {
  const final: string[] = section
    .split(" ")
    .reduce((acc: string[], cur: string) => {
      return acc.concat(textToClipPoints[cur]);
    }, []);
  const uniquePoints = [...new Set(final)];
  if (uniquePoints.length > 0) return `polygon(${final.join(", ")})`;
  return `none`;
}

export function tilesetColsCount(tileSetInfo: TileSetInfo) {
  return tileSetInfo.size.width / tileSetInfo.tileSize.width;
}

export function tilesetRowsCount(tileSetInfo: TileSetInfo) {
  return tileSetInfo.size.height / tileSetInfo.tileSize.height;
}

export function tilesetXOffset(tileSetInfo: TileSetInfo, index: number) {
  return (100 / (tilesetColsCount(tileSetInfo) - 1)) * index;
}

export function tilesetYOffset(tileSetInfo: TileSetInfo, index: number) {
  return (100 / (tilesetRowsCount(tileSetInfo) - 1)) * index;
}

export function animationFrameCount(startFrame: number, endFrame: number) {
  return endFrame - startFrame + 1;
}
export function animationDuration(
  startFrame: number,
  endFrame: number,
  frameDuration: number
) {
  return (animationFrameCount(startFrame, endFrame) * frameDuration) / 1000;
}
