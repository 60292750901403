import { useEffect, useRef, useState } from "react";
import bgLeft from "@wop/assets/images/background/background_left.png";
import bgCenter from "@wop/assets/images/background/background_center.png";
import bgRight from "@wop/assets/images/background/background_right.png";
import completedStamp from "@wop/assets/images/daily/completed-stamp.png";
import backgroundModal from "@wop/assets/images/modal/modal-bg.png";
import modalOrnamentTop from "@wop/assets/images/modal/modal-ornament-top.png";
import modalOrnamentBottom from "@wop/assets/images/modal/modal-ornament-bottom.png";
import comingSoonBackground from "@wop/assets/images/daily/coming-soon.png";

const IMAGES_TO_PRELOAD = [
  "/reveal.png",
  bgLeft,
  bgCenter,
  bgRight,
  "/node-base-all.png",
  "/node-overlay-primary.png",
  "/node-overlay-secondary.png",
  "/node-overlay-tertiary.png",
  "/node-mouse-event-overlay.png",
  "/node-start.png",
  "/paths.png",
  "/screen-flash.png",
  "/modal-loading.png",
  backgroundModal,
  modalOrnamentTop,
  modalOrnamentBottom,
  completedStamp,
  comingSoonBackground,
];

const usePreLoadElements = () => {
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const isLoaded = useRef(false);

  useEffect(() => {
    let mounted = true;
    IMAGES_TO_PRELOAD.forEach((image) => {
      const imageLoad = new Image();
      imageLoad.src = image;
      imageLoad.onload = () => {
        if (mounted) {
          setImagesLoaded((prevCompleted) => prevCompleted + 1);
        }
      };
    });

    return () => {
      setImagesLoaded(0);
      mounted = false;
    };
  }, []);

  if (imagesLoaded === IMAGES_TO_PRELOAD.length) {
    isLoaded.current = true;
  }

  function getPercentage() {
    return (imagesLoaded * 100) / IMAGES_TO_PRELOAD.length;
  }

  return { progress: getPercentage(), isLoaded: isLoaded.current };
};

export { usePreLoadElements };
