import { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { actions } from "@wop/redux/slices/AuthSlice";
import { useEffect } from "react";
type Props = {
  children: ReactElement;
};

const Auth0Wrapper = ({ children }: Props) => {
  const dispatch = useDispatch();

  const auth0 = useAuth0();

  useEffect(() => {
    dispatch(actions.setAuthContext(auth0));
  }, [auth0, dispatch]);

  return children;
};

export { Auth0Wrapper };
