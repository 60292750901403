import { styled } from "styled-components";
import useSound from "use-sound";
import { forwardRef, useEffect } from "react";
import {
  tilesetColsCount,
  tilesetRowsCount,
  tilesetXOffset,
} from "@wop/helpers/TileAnimationHelper";
import RevealSfx from "@wop/assets/sound/reveal.mp3";
import { useSelector } from "react-redux";
import { RootState } from "@wop/redux";

type RevealAnimationProps = {
  readonly $tileSetInfo: TileSetInfo;
  readonly $frameCount: number;
  readonly $frameDuration: number;
};

const StyledRevealAnimation = styled.div<RevealAnimationProps>`
  background-image: ${({ $tileSetInfo }) => `url(${$tileSetInfo.filename})`};
  background-size: ${({ $tileSetInfo }) =>
    `${100 * tilesetColsCount($tileSetInfo)}% ${100 * tilesetRowsCount($tileSetInfo)}%`};
  background-position: 0% 0%;
  background-repeat: no-repeat;
  image-rendering: pixelated;

  &.animate {
    background-position: 0% 0%;
    animation: ${({ $frameCount, $frameDuration }) =>
      `revealAnimateBg ${($frameCount * $frameDuration) / 1000}s steps(${$frameCount}, end) forwards`};
  }

  @keyframes revealAnimateBg {
    to {
      background-position-x: ${({ $tileSetInfo, $frameCount }) =>
        `${tilesetXOffset($tileSetInfo, $frameCount)}%`};
    }
  }
`;

const RevealAnimation = forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof StyledRevealAnimation>
>((props, ref) => {
  const isSfxEnabled = useSelector(
    (state: RootState) => state.sound.sfxEnabled
  );
  const [playRevealSfx] = useSound(RevealSfx, {
    interrupt: true,
    volume: 0.65,
    soundEnabled: isSfxEnabled,
  });

  useEffect(() => {
    playRevealSfx();
  }, [playRevealSfx]);

  return <StyledRevealAnimation ref={ref} {...props} />;
});

export { RevealAnimation };
