import { useAuth0 } from "@auth0/auth0-react";
import LogoutImage from "@wop/assets/images/icons/logout.png";
import { actions } from "@wop/redux/slices/AuthSlice";
import { useDispatch } from "react-redux";
import "./LoginButton.css";
import debounce from "lodash/debounce";
import * as Sentry from "@sentry/react";

const LogoutButton = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  const handleLogout = async () => {
    await dispatch(actions.setLoggingOut(true));
    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login`,
      },
    });
    actions.setLoggedIn(false);
    await dispatch(actions.logout());
    Sentry.setUser(null);
  };

  return (
    <div
      className="logout-button"
      onClick={debounce(handleLogout, 200, { leading: true, trailing: true })}
    >
      <img src={LogoutImage} alt="Log Out" />
    </div>
  );
};

export { LogoutButton };
