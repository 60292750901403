import { useState, useRef, useEffect, Fragment } from "react";
import { motion } from "framer-motion";
import "./Map.css";
import {
  Congratulations,
  NodeItem,
  PathItem,
  QuestModal,
  StartingNode,
  Screenflash,
  ChestNotification,
  WeeklyDungeonsNotification,
} from "@wop/components";
import bgLeft from "@wop/assets/images/background/background_left.png";
import bgCenter from "@wop/assets/images/background/background_center.png";
import bgRight from "@wop/assets/images/background/background_right.png";
import {
  DEFAULT_TILE_SIZE,
  DEFAULT_NUMBER_ROWS,
  DEFAULT_NUMBER_COLS,
  textToPathType,
  NodeItemStatus,
  PathItemStatus,
  NodeItemType,
} from "@wop/common";
import { formatAddress, hideBodyScroll } from "@wop/utils";
import { RevealAnimation } from "@wop/components/RevealAnimation/RevealAnimation";
import { writeOfPassageApi } from "@wop/redux/services/api";
import { RootState } from "@wop/redux";
import { useDispatch, useSelector } from "react-redux";
import { taskActions, actions } from "@wop/redux/slices";

type MapProps = {
  event: EventData;
  userResponse: UserResponse;
};

type NodeItemTypeDictionary = {
  [key: string]: NodeItemType;
};

type NodeItemStatusDictionary = {
  [key: string]: NodeItemStatus;
};

const revealTileSet: TileSetInfo = {
  filename: "reveal.png",
  size: { width: 16000, height: 288 },
  tileSize: { width: 640, height: 288 },
};

const textToNodeItemType: NodeItemTypeDictionary = {
  Primary: NodeItemType.Primary,
  Secondary: NodeItemType.Secondary,
  Tertiary: NodeItemType.Tertiary,
};

const textToNodeItemStatus: NodeItemStatusDictionary = {
  Unavailable: NodeItemStatus.Disabled,
  Available: NodeItemStatus.Enabled,
  AwaitingVerification: NodeItemStatus.Enabled,
  FailedVerification: NodeItemStatus.Enabled,
  Verified: NodeItemStatus.Enabled,
  Completed: NodeItemStatus.Completed,
};

const Map = ({ event, userResponse }: MapProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isZoomedIn, setZoomedIn] = useState(false);
  const [selectedQuestId, setSelectedQuestId] = useState("");
  const [showRevealAnimation, setShowRevealAnimation] = useState(true);
  const [showCongratulations, setShowCongratulations] = useState(false);
  const [isExitAllowed, setIsExitAllowed] = useState(true);
  const isItemSelected = useRef(false);
  const selectedItem = useRef({ x: -1, y: -1 });
  const scrollPos = useRef({ x: 0, y: 0 });
  const revealRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { isFetching: getUserEventIsFetching } =
    writeOfPassageApi.endpoints.getUserEvent.useQueryState({
      userId: userResponse.data.id,
      eventId: event.id,
    });

  const { requestId } = useSelector((state) => (state as RootState).auth0);

  const chestMinXpForNextChest = useSelector(
    (state) => (state as RootState).chest.chestMinXpForNextChest
  );
  const chestAvailableForCurrentXp = useSelector(
    (state) => (state as RootState).chest.chestAvailableForCurrentXp
  );
  const openedChests = userResponse?.data.UserEvent?.[0]?.openedChests || 0;
  const extraChests = userResponse?.data.UserEvent?.[0]?.extraChests || 0;
  const maxChests = chestAvailableForCurrentXp + extraChests;
  const chestsAvailable =
    maxChests > openedChests ? maxChests - openedChests : 0;

  const blockRevealAnimation: boolean = useSelector(
    (state) => (state as RootState).task.blockRevealAnimation
  );
  const userXp = userResponse?.data?.UserEvent?.[0]?.xp || 0;
  const pointsRemaining = chestMinXpForNextChest - userXp;

  const scaleFactor = 1;
  const mapPaddingY = 60;
  const navBarHeight = 96;
  const backgroundImageWidth = 480;
  const clientWidth = window.innerWidth;
  // const clientHeight = window.innerHeight;

  const mapWidth = DEFAULT_NUMBER_COLS * DEFAULT_TILE_SIZE.width;
  const transformOriginX =
    selectedItem.current.x * DEFAULT_TILE_SIZE.width +
    DEFAULT_TILE_SIZE.width / 2;
  const transformOriginY =
    selectedItem.current.y * DEFAULT_TILE_SIZE.width +
    mapPaddingY +
    navBarHeight -
    DEFAULT_TILE_SIZE.width / 2;

  const animateToX = Math.round(
    clientWidth / 2 + scrollPos.current.x - transformOriginX
  );
  // const animateToY = Math.round(
  //   (clientHeight - navBarHeight - mapPaddingY) / 2 +
  //     scrollPos.current.y -
  //     transformOriginY -
  //     DEFAULT_TILE_SIZE.height / 2
  // );
  const animateToY = 0;

  const handleScroll = (event: Event) => {
    const target = event.target as Document;
    scrollPos.current.x = target?.scrollingElement?.scrollLeft || 0;
    scrollPos.current.y = target?.scrollingElement?.scrollTop || 0;
  };

  const revealAnimationEnded = () => {
    setShowRevealAnimation(false);
    dispatch(taskActions.blockRevealAnimation());
  };

  useEffect(() => {
    if (!requestId) {
      dispatch(actions.setRequestMetrics(true));
    }
  }, [dispatch, requestId]);

  useEffect(() => {
    const revealDiv = revealRef.current;
    revealDiv?.addEventListener("animationend", revealAnimationEnded);
    document?.addEventListener("scroll", handleScroll, true);
    window.scrollTo(0, 0);
    return () => {
      revealDiv?.removeEventListener("animationend", revealAnimationEnded);
      document?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function itemZoom(enabled: boolean, x = -1, y = -1) {
    if (enabled) selectedItem.current = { x, y };
    isItemSelected.current = enabled;
    setZoomedIn(enabled);
  }

  function getUserQuestStatus(
    userQuestData: UserQuestData[] | undefined
  ): NodeItemStatus {
    const status = userQuestData?.[0]?.status;
    if (status && textToNodeItemStatus[status]) {
      return textToNodeItemStatus[status];
    } else {
      return NodeItemStatus.Disabled;
    }
  }

  function getUserQuestPathStatus(userQuestData: UserQuestData[] | undefined) {
    const nodeStatus = getUserQuestStatus(userQuestData);
    switch (nodeStatus) {
      case NodeItemStatus.Completed:
        return PathItemStatus.Completed;
      case NodeItemStatus.Enabled:
        return PathItemStatus.Enabled;
      case NodeItemStatus.Disabled:
      default:
        return PathItemStatus.Disabled;
    }
  }

  useEffect(() => {
    hideBodyScroll(
      isZoomedIn || (!blockRevealAnimation && showRevealAnimation)
    );
  }, [isZoomedIn, showRevealAnimation, blockRevealAnimation]);

  function hideModal() {
    if (!getUserEventIsFetching && isItemSelected.current && isZoomedIn) {
      itemZoom(false);
      setIsOpenModal(false);
      setSelectedQuestId("");
    }
  }

  return (
    <>
      <div
        id="divContainer"
        style={{
          paddingTop: `${mapPaddingY}px`,
          paddingBottom: `${mapPaddingY}px`,
          display: "flex",
          backgroundColor: "rgb(13,10, 18)",
        }}
      >
        {!blockRevealAnimation && showRevealAnimation && (
          <div className="flex w-[100vw] h-[100vh] absolute">
            <div className="flex-1 items-center justify-center">
              <RevealAnimation
                id="revealAnimation"
                ref={revealRef}
                className="absolute top-0 left-0 w-[1920px] h-[864px] z-[100] animate"
                $tileSetInfo={revealTileSet}
                $frameCount={25}
                $frameDuration={83}
              />
            </div>
          </div>
        )}

        <motion.div
          id="motionID"
          animate={{
            x: isZoomedIn ? animateToX : 0,
            y: isZoomedIn ? animateToY : 0,
            scale: isZoomedIn ? scaleFactor : 1,
          }}
          transition={{ type: "spring", stiffness: 400, damping: 40 }}
          style={{
            width: `${mapWidth}px`,
            height: `${DEFAULT_NUMBER_ROWS * DEFAULT_TILE_SIZE.height}px`,
            backgroundImage: `url(${bgLeft}), url(${bgRight}), url(${bgCenter})`,
            backgroundRepeat: `no-repeat, no-repeat, repeat`,
            backgroundPosition: `0 0, ${mapWidth - backgroundImageWidth}px 0, ${backgroundImageWidth}px`,
            backgroundSize: "contain",
            imageRendering: "pixelated",
            borderRadius: "5px",
            transformOrigin: `${transformOriginX}px ${transformOriginY}px`,
            display: "grid",
            gridTemplateColumns: `repeat(${DEFAULT_NUMBER_COLS}, minmax(${DEFAULT_TILE_SIZE.width}px, 1fr))`,
            gridTemplateRows: `repeat(${DEFAULT_NUMBER_ROWS}, minmax(${DEFAULT_TILE_SIZE.height}px, 1fr))`,
            gridGap: "0px",
          }}
        >
          <StartingNode gridPosition={{ x: 1, y: 4 }} />
          {event?.quests.map((quest: Quest) => {
            const type = textToNodeItemType[quest.type];
            const status = getUserQuestStatus(quest.UserQuest);
            return (
              <Fragment key={`fragment-${quest.id}`}>
                <NodeItem
                  id={quest.id}
                  key={quest.id}
                  gridPosition={{ x: quest.col, y: quest.row }}
                  type={type}
                  status={status}
                  shortDescription={quest.shortDescription}
                  xp={quest.xp}
                  onClick={() => {
                    if (!isZoomedIn && status !== NodeItemStatus.Disabled) {
                      setSelectedQuestId(quest.id);
                      itemZoom(true, quest.col, quest.row);
                      setIsOpenModal(true);
                    }
                  }}
                  isZoomedIn={isZoomedIn}
                  lastCompletedNodeId={event.lastCompletedNodeId}
                />
                {quest.children.map((questDependency: QuestDependency) => {
                  return questDependency.pathSegments.map(
                    (pathSegment: PathSegment) => {
                      return (
                        <PathItem
                          key={`${pathSegment.id}`}
                          gridPosition={{
                            x: pathSegment.col,
                            y: pathSegment.row,
                          }}
                          clip={pathSegment.clip}
                          type={textToPathType[pathSegment.type]}
                          sourceNodeId={questDependency.parentId}
                          targetNodeId={questDependency.childId}
                          isFinal={pathSegment.isFinal}
                          status={getUserQuestPathStatus(quest.UserQuest)}
                          lastCompletedNodeId={event.lastCompletedNodeId}
                        />
                      );
                    }
                  );
                })}
              </Fragment>
            );
          })}
        </motion.div>
      </div>
      <Screenflash />

      {isOpenModal && (
        <div
          className="fixed top-0 left-0 pt-[150px] pl-[30px] w-[100vw] h-[100vh] bg-[rgba(0,0,0,0.5)] z-[80]"
          onClick={() => {
            if (isExitAllowed) {
              hideModal();
            }
          }}
        >
          <QuestModal
            questId={selectedQuestId}
            exitAllowedCallback={(allow) => {
              setIsExitAllowed(allow);
            }}
            questCompletedCallback={(response) => {
              // Used to exit zoomed mode after animationDuration secs
              const animationDuration = 5;
              setIsExitAllowed(false);
              setIsOpenModal(false);
              setSelectedQuestId("");
              setTimeout(() => {
                itemZoom(false);
                setIsExitAllowed(true);
                if (response.isFinal) {
                  setShowCongratulations(true);
                }
              }, animationDuration * 1000);
            }}
          />
        </div>
      )}
      {showCongratulations && (
        <Congratulations onClickButton={() => setShowCongratulations(false)} />
      )}
      {(!showRevealAnimation || blockRevealAnimation) && (
        <div className="fixed bottom-[220px] z-[90] right-[0px] hidden lg:block">
          <WeeklyDungeonsNotification />
        </div>
      )}
      {(!showRevealAnimation || blockRevealAnimation) && (
        <div className="fixed bottom-[100px] z-[90] right-[0px] hidden lg:block">
          <ChestNotification
            chestsAvailable={chestsAvailable}
            pointsRemaining={pointsRemaining}
          />
        </div>
      )}
      {userResponse && userResponse.data.address && (
        <div className="fixed bottom-[10px] right-[20px] px-[5px] rounded-[2px] z-[100] font-[AstoriaRoman] leading-normal text-[16px] text-[rgb(92,92,94)] pointer-default">
          {formatAddress(userResponse.data.address)}
        </div>
      )}
    </>
  );
};

export { Map };
