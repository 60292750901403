import { createContext } from "react";
import {
  Auth0ContextInterface,
  initialContext,
  User as Auth0User,
} from "@auth0/auth0-react";
import { RankType } from "@wop/common";

const AUTH0_ACTION_NAMESPACE = import.meta.env.VITE_AUTH0_ACTION_NAMESPACE;

export function getBackgroundStyle(
  filePath: string,
  spriteSheetSize: Size,
  spriteSize: Size,
  itemX: number,
  itemY: number
) {
  const columnCount = spriteSheetSize.width / spriteSize.width;
  const rowCount = spriteSheetSize.height / spriteSize.height;
  const offsetX = (100 / (columnCount - 1)) * itemX;
  const offsetY = (100 / (rowCount - 1)) * itemY;

  return {
    backgroundImage: `url(${filePath})`,
    backgroundSize: `${100 * columnCount}% ${100 * rowCount}%`,
    backgroundPosition: `${offsetX}% ${offsetY}%`,
    backgroundRepeat: "no-repeat",
  };
}

export const linkContext = createContext<Auth0ContextInterface>(initialContext);

export const isUserLinkedToType = (
  userInfo: Auth0Profile,
  loginType: string
) => {
  const userPrimaryInfoFind = userInfo.identities.find(
    (identity) => identity.connection === loginType
  );
  return !!userPrimaryInfoFind;
};

export const isUserLinkedToAnyType = (userInfo: Auth0Profile) => {
  return userInfo.identities.length > 1;
};

export const customFetch = async (
  url: string,
  method: string,
  accessToken: string,
  body?: string
) => {
  const response = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: body,
  });
  return await response.json();
};

export const hideBodyScroll = (hide: boolean) => {
  const body = document.getElementsByTagName("body");
  body[0].style.overflow = hide ? "hidden" : "visible";
};

export const formatAddress = (address: string | null) => {
  if (!address) return "-";
  return `${address.slice(0, 6)}.....${address.slice(address.length - 5)}`;
};

export function getUserPayload(user?: Auth0User) {
  const userId = user?.[`${AUTH0_ACTION_NAMESPACE}/user_id`];
  if (userId) {
    const userPayload: GetUserPayload = {
      userId,
    };
    return userPayload;
  }
  return undefined;
}

function getDaySuffix(day: number): string {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function getFormattedDateWithSuffix(date: Date): string {
  const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric" };
  const formattedDate: string = date.toLocaleDateString("en-US", options);

  const day: number = date.getDate();
  const daySuffix: string = getDaySuffix(day);

  return formattedDate.replace(day.toString(), `${day}${daySuffix}`);
}

export const levelToRank = (l: number) => {
  if (l <= 5) {
    return RankType.Novice;
  }
  if (l >= 6 && l <= 10) {
    return RankType.Scout;
  }
  if (l >= 11 && l <= 15) {
    return RankType.Ranger;
  }
  if (l >= 16 && l <= 20) {
    return RankType.Vanguard;
  }
  // l >= 21
  return RankType.Landseeker;
};

export const prestigeToLevel = (p: number) => {
  if (p < 9500) {
    return Math.trunc(p / 500) + 1;
  } else if (p < 10500) {
    return 20;
  } else {
    return 21;
  }
};
